export const WORDS = [
  'ܟܠܒܬܐ',
  'ܐܬܘܬܐ',
  'ܓܘܪܒܐ',
  'ܓܠܝܢܐ',
  'ܒܘܪܟܐ',
  'ܬܝܡܢܐ',
  'ܡܥܪܒܐ',
  'ܓܪܒܝܐ',
  'ܡܕܢܚܐ',
  'ܘܠܝܬܐ',
  'ܓܝܓܠܐ',
  'ܝܕܥܬܐ',
  'ܟܝܡܝܐ',
  'ܡܐܟܢܐ',
  'ܡܕܝܪܐ',
  'ܡܠܘܫܐ',
  'ܥܠܥܠܐ',
  'ܦܛܝܚܐ',
  'ܦܠܦܠܐ',
  'ܫܘܚܬܐ',
  'ܪܥܝܢܐ',
  'ܪܒܝܥܐ',
  'ܫܐܕܢܐ',
  'ܫܘܐܠܐ',
  'ܐܓܪܬܐ',
  'ܐܘܪܚܐ',
  'ܐܝܠܢܐ',
  'ܐܝܡܡܐ',
  'ܡܪܒܥܐ',
  'ܐܢܬܬܐ',
  'ܐܣܘܪܐ',
  'ܐܣܝܬܐ',
  'ܐܪܥܬܐ',
  'ܐܢܬܘܢ',
  'ܐܢܬܝܢ',
  'ܐܪܢܒܐ',
  'ܒܝܥܬܐ',
  'ܒܠܘܛܐ',
  'ܒܪܘܢܐ',
  'ܒܪܝܬܐ',
  'ܓܒܝܢܐ',
  'ܓܘܫܡܐ',
  'ܓܠܝܕܐ',
  'ܓܢܒܪܐ',
  'ܗܝܟܠܐ',
  'ܝܪܘܩܐ',
  'ܫܥܘܬܐ',
  'ܘܪܝܕܐ',
  'ܙܡܪܬܐ',
  'ܚܒܘܫܐ',
  'ܚܘܓܬܐ',
  'ܚܘܫܒܐ',
  'ܚܛܝܬܐ',
  'ܚܙܘܢܐ',
  'ܚܟܡܬܐ',
  'ܚܝܘܬܐ',
  'ܛܘܦܣܐ',
  'ܝܘܡܢܐ',
  'ܝܡܝܢܐ',
  'ܓܫܘܫܐ',
  'ܟܘܟܒܐ',
  'ܟܬܒܬܐ',
  'ܫܘܓܠܐ',
  'ܠܝܛܪܐ',
  'ܐܚܝܢܐ',
  'ܡܒܘܥܐ',
  'ܡܘܒܠܐ',
  'ܡܘܠܕܐ',
  'ܡܝܛܪܐ',
  'ܡܛܠܬܐ',
  'ܡܡܠܠܐ',
  'ܡܠܦܢܐ',
  'ܡܣܐܬܐ',
  'ܡܢܝܢܐ',
  'ܡܥܠܬܐ',
  'ܡܦܩܬܐ',
  'ܡܪܪܬܐ',
  'ܡܪܟܒܐ',
  'ܢܘܗܪܐ',
  'ܢܡܘܣܐ',
  'ܚܘܡܫܐ',
  'ܢܩܒܬܐ',
  'ܣܘܕܪܐ',
  'ܣܘܟܠܐ',
  'ܣܘܟܢܐ',
  'ܣܘܣܬܐ',
  'ܣܟܝܢܐ',
  'ܟܘܢܝܐ',
  'ܣܥܪܬܐ',
  'ܣܪܛܢܐ',
  'ܚܠܘܠܐ',
  'ܟܪܦܬܐ',
  'ܠܡܐܢܐ',
  'ܥܩܪܒܐ',
  'ܥܪܦܠܐ',
  'ܦܣܘܩܐ',
  'ܓܘܢܢܐ',
  'ܦܬܘܪܐ',
  'ܡܥܒܪܐ',
  'ܨܒܥܬܐ',
  'ܨܘܪܬܐ',
  'ܨܠܝܒܐ',
  'ܙܘܝܬܐ',
  'ܩܒܘܪܐ',
  'ܩܘܪܒܐ',
  'ܩܘܡܬܐ',
  'ܩܝܢܝܐ',
  'ܩܢܘܢܐ',
  'ܩܫܝܫܐ',
  'ܥܡܘܪܐ',
  'ܙܟܘܬܐ',
  'ܐܬܘܢܐ',
  'ܐܒܗܬܐ',
  'ܪܘܒܥܐ',
  'ܟܘܬܪܐ',
  'ܕܘܓܠܐ',
  'ܩܠܝܡܐ',
  'ܢܘܨܦܐ',
  'ܢܘܓܗܐ',
  'ܐܪܘܢܐ',
  'ܫܘܚܕܐ',
  'ܫܒܘܥܐ',
  'ܪܩܝܥܐ',
  'ܫܪܒܬܐ',
  'ܝܠܘܦܐ',
  'ܫܪܘܬܐ',
  'ܫܪܝܬܐ',
  'ܬܘܪܬܐ',
  'ܬܢܘܪܐ',
  'ܬܢܝܢܐ',
  'ܬܪܡܠܐ',
  'ܬܪܘܕܐ',
  'ܘܙܝܪܐ',
  'ܡܣܪܩܐ',
  'ܚܙܘܪܐ',
  'ܚܢܘܬܐ',
  'ܕܦܬܪܐ',
  'ܡܥܡܪܐ',
  'ܚܘܠܦܐ',
  'ܢܘܩܙܐ',
  'ܬܪܘܨܐ',
  'ܫܘܪܝܐ',
  'ܡܬܩܠܐ',
  'ܡܘܕܟܐ',
  'ܒܘܫܠܐ',
  'ܣܘܠܩܐ',
  'ܕܒܫܬܐ',
  'ܫܘܪܛܐ',
  'ܦܠܝܛܐ',
  'ܓܘܢܝܐ',
  'ܙܩܝܦܐ',
  'ܩܪܣܝܐ',
  'ܥܠܝܡܐ',
  'ܬܘܠܬܐ',
  'ܢܝܙܟܐ',
  'ܡܣܓܕܐ',
  'ܬܘܬܪܐ',
  'ܬܘܩܢܐ',
  'ܥܠܘܠܐ',
  'ܓܘܠܥܐ',
  'ܡܟܬܒܐ',
  'ܩܠܝܬܐ',
  'ܟܒܝܪܐ',
  'ܒܢܝܢܐ',
  'ܦܢܕܩܐ',
  'ܒܛܡܬܐ',
  'ܝܪܩܢܐ',
  'ܐܫܟܦܐ',
  'ܕܘܟܬܐ',
  'ܣܦܣܠܐ',
  'ܣܒܠܬܐ',
  'ܡܠܩܛܐ',
  'ܗܢܝܢܐ',
  'ܣܡܘܩܐ',
  'ܚܘܕܬܐ',
  'ܟܘܪܦܐ',
  'ܪܘܓܙܐ',
  'ܩܪܘܛܐ',
  'ܓܕܝܫܐ',
  'ܚܘܡܠܐ',
  'ܥܪܒܝܐ',
  'ܓܘܒܝܐ',
  'ܓܕܘܕܐ',
  'ܚܘܕܪܐ',
  'ܥܪܙܠܐ',
  'ܦܫܝܛܐ',
  'ܬܡܢܝܐ',
  'ܐܪܒܥܐ',
  'ܐܚܘܢܐ',
  'ܦܘܢܕܐ',
  'ܐܕܝܘܡ',
  'ܚܕܥܣܪ',
  'ܬܫܥܣܪ',
  'ܫܒܥܣܪ',
  'ܬܪܥܣܪ',
  'ܣܘܪܝܐ',
  'ܘܪܩܬܐ',
  'ܟܟܡܟܐ',
  'ܐܟܘܡܐ',
  'ܙܪܝܦܐ',
  'ܨܘܢܡܝ',
  'ܬܙܘܬܐ',
  'ܒܣܝܡܐ',
  'ܬܪܓܡܢ',
  'ܡܬܪܓܡ',
  'ܩܕܝܠܐ',
  'ܨܘܠܬܐ',
  'ܟܪܝܗܐ',
  'ܚܫܘܒܐ',
  'ܐܘܪܙܐ',
  'ܪܕܝܬܐ',
  'ܫܚܝܡܐ',
  'ܡܗܡܙܡ',
  'ܡܫܚܠܦ',
  'ܚܘܪܡܐ',
  'ܬܫܟܝܐ',
  'ܪܘܣܝܐ',
  'ܒܢܦܫܐ',
  'ܥܬܝܩܐ',
  'ܦܘܫܝܐ',
  'ܓܘܪܓܐ',
  'ܐܚܬܘܢ',
  'ܬܐܘܡܐ',
  'ܨܪܒܝܐ',
  'ܡܚܘܒܐ',
  'ܟܠܝܩܐ',
  'ܦܪܒܠܐ',
  'ܫܦܬܝܐ',
  'ܟܬܝܬܐ',
  'ܝܘܦܩܐ',
  'ܡܨܥܝܐ',
  'ܨܠܘܢܐ',
  'ܙܥܘܪܐ',
  'ܥܣܪܬܐ',
  'ܕܩܝܩܐ',
  'ܬܪܬܝܢ',
  'ܢܘܢܬܐ',
  'ܦܢܓܢܐ',
  'ܡܦܪܦܠ',
  'ܡܦܪܡܐ',
  'ܟܪܘܝܫ',
  'ܒܢܘܢܐ',
  'ܝܠܕܬܐ',
  'ܡܠܝܘܢ',
  'ܫܘܝܫܐ',
  'ܡܫܠܝܐ',
  'ܫܪܝܪܐ',
  'ܪܡܩܠܐ',
  'ܡܟܠܝܐ',
  'ܦܪܫܬܐ',
  'ܚܘܪܬܐ',
  'ܙܪܓܬܐ',
  'ܛܝܣܬܐ',
  'ܡܣܚܒܪ',
  'ܪܨܝܦܐ',
  'ܕܘܟܢܐ',
  'ܛܐܠܢܐ',
  'ܡܒܪܣܡ',
  'ܛܒܘܥܐ',
  'ܚܡܝܡܐ',
  'ܩܪܝܪܐ',
  'ܡܨܚܨܐ',
  'ܗܝܪܬܐ',
  'ܛܝܘܣܐ',
  'ܡܣܚܝܐ',
  'ܡܘܠܦܐ',
  'ܡܠܘܦܐ',
  'ܡܚܫܒܢ',
  'ܐܪܟܝܐ',
  'ܛܒܝܒܐ',
  'ܒܪܝܟܐ',
  'ܥܘܕܠܐ',
  'ܢܒܓܬܐ',
  'ܬܪܝܢܐ',
  'ܩܕܡܝܐ',
  'ܩܘܕܡܐ',
  'ܡܘܣܝܐ',
  'ܒܟܬܬܐ',
  'ܗܠܝܘܢ',
  'ܚܐܪܬܐ',
  'ܣܡܝܬܐ',
  'ܓܒܪܬܐ',
  'ܚܕܬܬܐ',
  'ܐܚܘܬܐ',
  'ܚܬܘܬܐ',
  'ܡܕܠܩܒ',
  'ܦܚܠܬܐ',
  'ܒܫܝܢܐ',
  'ܝܘܗܒܐ',
  'ܫܟܪܬܐ',
  'ܕܟܝܪܐ',
  'ܡܫܬܐܣ',
  'ܚܡܝܢܐ',
  'ܟܝܣܬܐ',
  'ܨܚܬܝܢ',
  'ܡܒܪܒܙ',
  'ܡܒܙܒܙ',
  'ܡܓܪܓܫ',
  'ܡܫܪܝܐ',
  'ܐܚܪܝܐ',
  'ܓܪܘܪܐ',
  'ܡܩܠܝܐ',
  'ܗܘܝܬܐ',
  'ܕܪܓܬܐ',
  'ܚܒܝܒܐ',
  'ܩܢܫܬܐ',
  'ܫܒܥܝܢ',
  'ܥܙܝܙܐ',
  'ܣܡܠܬܐ',
  'ܛܘܝܣܐ',
  'ܫܘܝܬܐ',
  'ܡܒܕܒܕ',
  'ܡܙܡܙܡ',
  'ܛܫܗܝܐ',
  'ܡܬܩܬܩ',
  'ܩܕܝܡܐ',
  'ܒܪܚܡܐ',
  'ܐܟܪܬܐ',
  'ܡܢ ܟܕ',
  'ܐܪܝܬܐ',
  'ܠܘܣܬܐ',
  'ܒܒܘܬܐ',
  'ܡܩܪܢܐ',
  'ܣܘܕܝܐ',
  'ܡܚܡܚܡ',
  'ܪܘܡܬܐ',
  'ܐܕܫܢܬ',
  'ܚܙܝܢܐ',
  'ܕܥܬܝܕ',
  'ܡܫܘܬܒ',
  'ܡܪܝܪܐ',
  'ܛܥܝܡܐ',
  'ܒܪܫܝܬ',
  'ܣܘܓܠܐ',
  'ܟܡܝܪܐ',
  'ܒܩܠܘܐ',
  'ܣܚܝܢܐ',
  'ܝܘܕܥܐ',
  'ܪܡܘܢܐ',
  'ܬܢܝܬܐ',
  'ܡܫܠܗܒ',
  'ܐܟܝܬܘ',
  'ܫܬܩܕܝ',
  'ܛܘܪܝܐ',
  'ܟܡܝܥܐ',
  'ܠܗܝܒܐ',
  'ܫܬܝܬܐ',
  'ܦܝܠܣܐ',
  'ܡܘܬܢܐ',
  'ܩܪܘܪܐ',
  'ܡܣܪܥܦ',
  'ܥܒܪܝܐ',
  'ܩܘܦܠܐ',
  'ܦܕܝܬܐ',
  'ܡܫܘܕܥ',
  'ܡܟܠܟܠ',
  'ܩܪܝܒܐ',
  'ܦܘܟܪܐ',
  'ܢܗܪܝܢ',
  'ܡܙܢܓܪ',
  'ܟܢܘܢܐ',
  'ܡܚܦܪܦ',
  'ܫܟܝܪܐ',
  'ܩܠܘܠܐ',
  'ܡܫܝܕܢ',
  'ܣܦܝܩܐ',
  'ܡܬܫܘܫ',
  'ܐܡܙܝܓ',
  'ܡܒܠܕܪ',
  'ܣܡܠܝܐ',
  'ܟܠܫܢܬ',
  'ܡܥܙܬܐ',
  'ܡܪܝܥܐ',
  'ܣܘܗܕܐ',
  'ܠܩܠܩܐ',
  'ܬܪܝܢܝ',
  'ܫܚܘܡܐ',
  'ܬܠܝܬܝ',
  'ܚܕܢܝܐ',
  'ܝܠܝܕܐ',
  'ܐܣܝܪܐ',
  'ܡܪܦܪܦ',
  'ܠܐ ܟܝ',
  'ܒܘܩܬܐ',
  'ܐܫܛܪܐ',
  'ܠܒܢܬܐ',
  'ܡܢ ܕ-',
  'ܡܦܬܚܐ',
  'ܓܡܠܬܐ',
  'ܡܘܚܒܐ',
  'ܝܘܢܝܐ',
  'ܦܪܣܝܐ',
  'ܡܩܪܩܪ',
  'ܡܩܛܩܛ',
  'ܗܡܙܡܢ',
  'ܡܫܥܡܪ',
  'ܟܠܢܝܐ',
  'ܨܪܘܚܐ',
  'ܡܟܫܟܫ',
  'ܚܘܠܢܐ',
  'ܠܘܘܝܐ',
  'ܛܒܢܓܐ',
  'ܟܘܬܩܐ',
  'ܝܠܦܘܟ',
  'ܫܦܝܪܐ',
  'ܦܝܬܣܐ',
  'ܣܘܦܪܐ',
  'ܣܡܝܟܐ',
  'ܚܕܟܡܐ',
  'ܡܠܝܪܕ',
  'ܟܝܢܝܐ',
  'ܨܝܢܝܐ',
  'ܡܬܢܬܢ',
  'ܩܢܝܬܐ',
  'ܙܥܘܩܐ',
  'ܥܬܝܪܐ',
  'ܡܟܙܟܙ',
  'ܡܬܠܬܐ',
  'ܡܪܬܪܬ',
  'ܡܙܥܙܥ',
  'ܓܘܒܪܐ',
  'ܒܪܩܝܐ',
  'ܝܘܪܟܐ',
  'ܡܝܩܪܐ',
  'ܡܨܪܝܐ',
  'ܐܝܡܝܠ',
  'ܟܗܪܒܐ',
  'ܟܟܝܚܐ',
  'ܡܐܠܥܐ',
  'ܬܪܨܢܐ',
  'ܗܪܘܡܐ',
  'ܐܪܒܥܬ',
  'ܩܦܘܚܐ',
  'ܡܩܪܡܟ',
  'ܒܪܟܟܐ',
  'ܒܘܡܒܐ',
  'ܡܐܬܝܢ',
  'ܬܡܢܝܬ',
  'ܬܠܦܦܐ',
  'ܐܠܦܝܢ',
  'ܫܘܬܬܐ',
  'ܢܣܝܪܐ',
  'ܡܬܚܙܐ',
  'ܡܚܝܕܐ',
  'ܘܫܪܟܐ',
  'ܬܘܓܓܐ',
  'ܟܠܝܦܐ',
  'ܣܝܢܡܐ',
  'ܡܒܩܠܐ',
  'ܝܬܘܡܐ',
  'ܣܒܝܗܐ',
  'ܡܣܩܠܩ',
  'ܠܩܕܡܐ',
  'ܡܫܡܫܢ',
  'ܡܢܕܝܐ',
  'ܡܚܕܕܐ',
  'ܐܚܕܕܐ',
  'ܡܣܙܓܪ',
  'ܡܩܦܣܐ',
  'ܣܢܝܩܐ',
  'ܥܠܐܣܣ',
  'ܡܚܪܛܐ',
  'ܡܓܪܒܢ',
  'ܫܠܝܛܐ',
  'ܐܒܝܫܐ',
  'ܡܣܡܣܡ',
  'ܗܕܝܐܐ',
  'ܚܘܪܓܐ',
  'ܝܡܡܝܐ',
  'ܣܡܪܢܐ',
  'ܡܚܡܫܐ',
  'ܒܣܒܪܐ',
  'ܐܚܪܢܐ',
  'ܡܫܘܚܪ',
  'ܟܪܟܢܐ',
  'ܡܛܪܛܡ',
  'ܝܪܝܟܐ',
  'ܚܬܝܬܐ',
  'ܠܗܘܝܐ',
  'ܒܕܘܟܬ',
  'ܡܘܢܚܐ',
  'ܕܘܢܝܐ',
  'ܟܦܦܬܐ',
  'ܣܢܣܪܐ',
  'ܪܓܘܠܐ',
  'ܩܘܬܝܐ',
  'ܩܒܘܬܐ',
  'ܪܝܫܝܐ',
  'ܬܚܬܝܐ',
  'ܪܚܝܩܐ',
  'ܕܡܝܢܐ',
  'ܡܕܡܕܡ',
  'ܡܐܝܟܐ',
  'ܒܚܪܬܐ',
  'ܟܪܝܒܐ',
  'ܒܠܘܢܐ',
  'ܡܘܙܘܙ',
  'ܣܡܘܝܐ',
  'ܡܚܕܬܐ',
  'ܠܥܘܣܐ',
  'ܡܚܡܠܐ',
  'ܡܟܩܟܩ',
  'ܟܪܣܢܐ',
  'ܚܘܝܨܐ',
  'ܥܡܡܝܐ',
  'ܣܘܝܟܐ',
  '-ܘܟܘܢ',
  'ܢܬܝܓܐ',
  'ܫܩܝܬܐ',
  'ܚܘܪܝܐ',
  'ܙܪܘܩܐ',
  'ܘܪܘܕܐ',
  'ܩܛܘܡܐ',
  'ܡܟܠܒܢ',
  'ܡܣܡܣܩ',
  'ܪܚܘܩܐ',
  'ܐܚܘܕܐ',
  'ܒܗܐܝܐ',
  'ܢܩܝܕܐ',
  'ܐܛܘܡܐ',
  'ܦܩܘܚܐ',
  'ܟܡܘܕܐ',
  'ܨܘܕܪܐ',
  'ܝܒܝܫܐ',
  'ܣܓܝܐܐ',
  'ܠܒܨܝܪ',
  'ܦܠܓܝܐ',
  'ܟܘܐܠܐ',
  'ܡܩܢܛܪ',
  'ܗܢܝܐܐ',
  'ܡܫܚܬܢ',
  'ܓܘܢܩܐ',
  'ܠܒܬܪܐ',
  'ܡܦܪܓܐ',
  'ܒܪܚܡܝ',
  'ܝܩܘܪܐ',
  'ܢܥܝܡܐ',
  'ܓܘܐܝܬ',
  'ܢܫܝܢܐ',
  'ܡܫܘܬܦ',
  'ܒܠܚܘܕ',
  'ܒܗܦܟܐ',
  'ܚܘܨܪܐ',
  'ܗܘܦܟܐ',
  'ܥܬܝܕܐ',
  'ܨܒܘܬܐ',
  'ܡܛܒܚܐ',
  'ܡܛܥܡܐ',
  'ܪܛܝܒܐ',
  'ܙܠܡܘܢ',
  'ܥܡܘܩܐ',
  'ܣܩܘܡܐ',
  'ܡܒܢܝܐ',
  'ܨܘܒܝܐ',
  'ܫܪܫܝܐ',
  'ܐܡܝܢܐ',
  'ܙܠܝܡܐ',
  'ܦܬܝܚܐ',
  'ܐܢܫܝܐ',
  'ܪܕܘܕܐ',
  'ܕܡܝܟܐ',
  'ܒܫܝܠܐ',
  'ܗܘܢܢܐ',
  'ܚܟܝܡܐ',
  'ܚܣܝܢܐ',
  'ܙܕܝܩܐ',
  'ܚܠܝܡܐ',
  'ܚܡܘܨܐ',
  'ܡܠܘܚܐ',
  'ܬܝܡܘܢ',
  'ܦܪܠܡܢ',
  'ܝܨܝܦܐ',
  'ܝܬܝܪܐ',
  'ܝܩܝܕܐ',
  'ܒܨܝܪܐ',
  'ܟܢܝܫܐ',
  'ܟܦܝܢܐ',
  'ܟܬܝܒܐ',
  'ܡܚܕܝܐ',
  'ܫܠܝܨܐ',
  'ܦܫܝܡܐ',
  'ܪܡܘܙܐ',
  'ܡܘܥܕܐ',
  'ܡܬܚܡܢ',
  'ܦܫܝܩܐ',
  'ܟܘܫܝܐ',
  'ܥܠܘܝܐ',
  'ܫܐܪܬܐ',
  'ܡܕܝܢܐ',
  'ܫܒܒܝܐ',
  'ܩܐܡܝܐ',
  'ܥܝܕܝܐ',
  'ܚܪܝܦܐ',
  'ܢܨܪܝܐ',
  'ܡܫܚܛܐ',
  'ܡܚܪܡܛ',
  'ܡܙܘܓܐ',
  'ܐܘܦܩܐ',
  'ܥܘܩܢܐ',
  'ܐܨܠܝܐ',
  'ܡܝܬܪܐ',
  'ܢܘܣܟܐ',
  'ܬܦܢܟܐ',
  'ܦܝܫܢܐ',
  'ܡܬܦܢܟ',
  'ܩܘܪܨܐ',
  'ܚܫܘܠܐ',
  'ܢܣܘܟܐ',
  'ܚܠܝܨܐ',
  'ܣܒܝܪܐ',
  'ܚܫܝܫܐ',
  'ܚܫܘܫܐ',
  'ܒܪܡܫܐ',
  'ܐܨܛܚܢ',
  'ܫܢܝܙܐ',
  'ܙܓܝܪܐ',
  'ܕܪܥܢܐ',
  'ܡܓܠܓܠ',
  'ܡܟܪܡܟ',
  'ܬܟܝܠܐ',
  'ܣܘܟܪܐ',
  'ܐܬܝܢܐ',
  'ܕܝܬܩܐ',
  'ܕܘܪܪܐ',
  'ܟܒܘܫܐ',
  'ܫܢܝܢܐ',
  'ܡܬܝܢܐ',
  'ܟܘܫܦܐ',
  'ܚܠܝܦܐ',
  'ܠܚܝܦܐ',
  'ܘܠܝܝܐ',
  'ܩܝܪܛܐ',
  'ܬܠܬܝܢ',
  'ܥܣܪܝܢ',
  'ܬܫܥܝܢ',
  'ܐܫܬܝܢ',
  'ܬܡܢܝܢ',
  'ܚܡܫܝܢ',
  'ܓܘܒܬܐ',
  'ܕܘܟܠܐ',
  'ܡܓܪܓܡ',
  'ܡܓܢܝܐ',
  'ܬܪܝܨܐ',
  'ܦܘܬܚܐ',
  'ܚܕܪܘܢ',
  'ܡܒܨܒܨ',
  'ܦܠܢܛܐ',
  'ܙܡܝܢܐ',
  'ܚܡܘܕܐ',
  'ܐܘܫܦܐ',
  'ܪܘܦܫܐ',
  'ܩܡܘܙܐ',
  'ܡܫܡܗܐ',
  'ܡܢܣܝܐ',
  'ܫܡܚܢܐ',
  'ܝܐܝܒܐ',
  'ܒܬܪܟܢ',
  'ܩܘܠܥܐ',
  'ܥܒܘܝܐ',
  'ܡܥܘܩܐ',
  'ܚܠܩܬܐ',
  'ܡܬܟܬܒ',
  'ܡܚܙܕܓ',
  'ܓܘܢܚܐ',
  'ܡܓܢܓܪ',
  'ܡܓܢܓܙ',
  'ܫܘܪܒܐ',
  'ܡܗܘܢܢ',
  'ܗܘܝܐܐ',
  'ܒܗܘܪܐ',
  'ܕܘܪܬܐ',
  'ܫܠܝܡܐ',
  'ܡܨܢܥܐ',
  'ܩܐ ܕ-',
  'ܛܠܘܡܐ',
  'ܚܘܪܕܐ',
  'ܝܬܒܬܐ',
  'ܟܕܝܒܐ',
  'ܛܥܝܫܐ',
  'ܝܘܐܒܐ',
  'ܛܦܝܬܐ',
  'ܟܝܘܠܐ',
  'ܡܨܢܨܠ',
  'ܙܝܘܢܐ',
  'ܫܚܝܢܐ',
  'ܪܓܝܙܐ',
  'ܩܘܛܒܐ',
  'ܩܝܘܡܐ',
  'ܐܐܪܝܐ',
  'ܡܬܩܢܐ',
  'ܓܘܝܒܐ',
  'ܐܡܘܪܐ',
  'ܡܬܪܥܣ',
  'ܡܢܘܢܐ',
  'ܥܘܣܪܐ',
  'ܬܘܡܢܐ',
  'ܐܠܦܝܐ',
  'ܥܣܪܝܐ',
  'ܫܘܒܥܐ',
  'ܬܘܫܥܐ',
  'ܡܘܛܝܐ',
  'ܫܒܘܛܐ',
  'ܡܬܦܬܦ',
  'ܡܚܪܙܦ',
  'ܡܨܪܦܚ',
  'ܡܐܦܝܐ',
  'ܦܪܝܫܐ',
  'ܡܚܠܦܐ',
  'ܓܘܠܦܐ',
  'ܚܘܘܬܐ',
  'ܗܠܝܘܡ',
  'ܥܪܝܨܐ',
  'ܡܥܠܢܐ',
  'ܡܦܩܢܐ',
  'ܛܒܝܥܐ',
  'ܥܪܒܢܐ',
  'ܡܥܪܘܨ',
  'ܪܩܝܩܐ',
  'ܣܝܛܠܐ',
  'ܒܘܥܕܐ',
  'ܩܛܝܢܐ',
  'ܕܒܘܫܐ',
  'ܡܒܠܒܠ',
  'ܡܕܫܕܫ',
  'ܡܩܘܡܐ',
  'ܡܛܪܡܫ',
  'ܒܪܢܫܐ',
  'ܡܦܪܦܥ',
  'ܩܘܦܣܐ',
  'ܡܛܚܢܐ',
  'ܬܓܪܝܐ',
  'ܢܥܡܬܐ',
  'ܒܢܝܩܐ',
  'ܥܣܩܬܐ',
  'ܫܪܘܝܐ',
  'ܡܩܢܘܢ',
  'ܡܣܘܟܢ',
  'ܡܕܫܡܢ',
  'ܫܪܘܩܐ',
  'ܟܫܝܪܐ',
  'ܡܬܫܥܐ',
  'ܠܘܥܬܐ',
  'ܡܒܠܩܢ',
  'ܥܢܝܢܐ',
  'ܡܫܥܠܐ',
  'ܡܥܪܦܠ',
  'ܡܫܬܝܐ',
  'ܐܝܕܡܐ',
  'ܝܓܝܣܐ',
  'ܐܝܠܝܢ',
  'ܡܥܠܬܢ',
  'ܛܘܦܪܐ',
  'ܡܫܡܠܐ',
  'ܡܟܝܪܐ',
  'ܛܘܟܣܐ',
  'ܬܡܝܡܐ',
  'ܣܢܐܬܐ',
  'ܢܦܝܩܐ',
  'ܥܠܝܠܐ',
  'ܫܒܛܪܐ',
  'ܡܫܝܢܐ',
  'ܩܢܝܛܐ',
  'ܪܘܒܢܐ',
  'ܩܛܘܢܐ',
  'ܫܟܝܚܐ',
  'ܒܛܝܠܐ',
  'ܦܥܝܠܐ',
  'ܡܣܩܒܠ',
  'ܢܨܝܚܐ',
  'ܕܪܘܙܐ',
  'ܡܣܡܟܐ',
  'ܝܠܚܬܐ',
  'ܬܘܩܦܐ',
  'ܓܪܘܡܐ',
  'ܬܠܝܠܐ',
  'ܣܝܘܡܐ',
  'ܡܣܪܗܒ',
  'ܓܠܝܙܐ',
  'ܡܪܫܡܐ',
  'ܓܘܬܬܐ',
  'ܦܝܘܚܐ',
  'ܓܠܘܠܐ',
  'ܒܝܕܝܘ',
  'ܗܘ ܚܕ',
  'ܦܝܠܡܐ',
  'ܩܛܥܢܐ',
  'ܚܒܝܨܐ',
  'ܚܘܒܬܐ',
  'ܡܠܝܠܐ',
  'ܡܗܝܪܐ',
  'ܒܚܝܠܐ',
  'ܡܚܝܠܐ',
  'ܙܪܝܙܐ',
  'ܓܨܘܨܐ',
  'ܒܪܒܒܐ',
  'ܚܒܘܛܐ',
  'ܒܨܦܪܐ',
  'ܡܫܪܒܛ',
  'ܚܠܘܝܐ',
  'ܛܡܘܡܐ',
  'ܚܫܘܟܐ',
  'ܒܒܘܢܐ',
  'ܡܚܪܒܫ',
  'ܗܡܝܪܐ',
  'ܒܣܝܪܐ',
  'ܒܘܪܨܐ',
  'ܩܘܒܠܐ',
  'ܫܦܘܠܐ',
  'ܒܠܝܓܐ',
  'ܢܙܠܬܐ',
  'ܓܪܘܕܐ',
  'ܪܓܘܫܐ',
  'ܒܢܙܝܢ',
  'ܚܟܘܟܐ',
  'ܚܘܟܟܐ',
  'ܩܘܪܛܐ',
  'ܠܘܩܛܐ',
  'ܡܙܪܙܛ',
  'ܣܘܪܦܐ',
  'ܐܦ ܚܕ',
  'ܐܙܠܬܐ',
  'ܚܫܚܝܐ',
  'ܐܦ ܠܐ',
  'ܫܓܝܡܐ',
  'ܐܠܨܝܐ',
  'ܠܚܡܝܐ',
  'ܩܪܝܚܐ',
  'ܬܘܬܢܐ',
  'ܪܬܝܚܐ',
  'ܐܬܝܬܐ',
  'ܫܓܘܡܐ',
  'ܡܫܪܬܚ',
  'ܦܠܥܢܐ',
  'ܢܛܘܪܐ',
  'ܒܪܩܬܐ',
  'ܚܫܠܬܐ',
  'ܟܘܚܬܐ',
  'ܡܕܠܝܐ',
  'ܙܘܢܪܐ',
  'ܥܒܝܕܐ',
  'ܦܝܙܝܐ',
  'ܣܦܝܪܐ',
  'ܥܘܝܡܐ',
  'ܡܫܓܬܐ',
  'ܛܘܝܠܐ',
  'ܣܛܪܝܐ',
  'ܡܫܩܫܩ',
  'ܡܫܥܒܕ',
  'ܟܘܪܚܐ',
  'ܫܝܪܝܐ',
  'ܨܘܒܥܐ',
  'ܛܝܠܬܐ',
  'ܢܟܘܦܐ',
  'ܡܦܪܦܫ',
  'ܡܬܦܬܫ',
  'ܟܣܘܝܐ',
  'ܟܪܘܟܐ',
  'ܝܪܘܬܐ',
  'ܚܙܘܩܐ',
  'ܫܒܘܪܐ',
  'ܙܘܗܪܐ',
  'ܟܘܠܒܐ',
  'ܝܩܪܬܐ',
  'ܕܘܝܩܐ',
  'ܣܘܪܩܐ',
  'ܡܣܪܛܐ',
  'ܡܕܩܕܩ',
  'ܫܘܪܬܐ',
  'ܦܣܬܩܐ',
  'ܫܘܠܦܐ',
  'ܚܘܫܬܐ',
  'ܡܫܪܫܪ',
  'ܟܢܝܙܐ',
  'ܚܠܘܨܐ',
  'ܕܥܝܟܐ',
  'ܡܙܝܕܐ',
]
export const WORDS_WITH_VOWELS = [
  'ܟܲܠܵܒ݂ܬܵܐ',
  'ܐܵܬܘܼܬܵܐ',
  'ܓܘܼܪܒ݂ܵܐ',
  'ܓܸܠܝܵܢܵܐ',
  'ܒܘܼܪܵܟ݂ܵܐ',
  'ܬܲܝܡܢܵܐ',
  'ܡܲܥܪܒ݂ܵܐ',
  'ܓܲܪܒܝܵܐ',
  'ܡܲܕܢܚܵܐ',
  'ܘܵܠܝܼܬܵܐ',
  'ܓܝܼܓ݂ܠܵܐ',
  'ܝܲܕ݂̈ܥܵܬ݂ܹܐ',
  'ܟܝܼܡܝܼܵܐ',
  'ܡܵܐܟܹܢܵܐ',
  'ܡܸܕ݂ܝܵܪܵܐ',
  'ܡܲܠܘܵܫܵܐ',
  'ܥܲܠܥܵܠܵܐ',
  'ܦܲܛܝܼܚܵܐ',
  'ܦܸܠܦܠܵܐ',
  'ܫܘܼܚܬܵܐ',
  'ܪܸܥܝܵܢܵܐ',
  'ܪܒ݂ܝܼܥܵܐ',
  'ܫܹܐܕ݂ܵܢܵܐ',
  'ܫܘܼܐܵܠܵܐ',
  'ܐܸܓܲܪܬܵܐ',
  'ܐܘܼܪܚܵܐ',
  'ܐܝܼܠܵܢܵܐ',
  'ܐܝܼܡܵܡܵܐ',
  'ܡܪܲܒܥܵܐ',
  'ܐܲܢ݇ܬܬ݂ܵܐ',
  'ܐܲܣܘܼܪܵܐ',
  'ܐܵܣܝܼܬܵܐ',
  'ܐܲܪ̈ܥܵܬ݂ܹܐ',
  'ܐܲܢ݇ܬܘܿܢ',
  'ܐܲܢܬܹܝܢ',
  'ܐܲܪܢܒ݂ܵܐ',
  'ܒܹܝܥܬ݂ܵܐ',
  'ܒܲܠܘܼܛܵܐ',
  'ܒܪܘܿܢܵܐ',
  'ܒܪܝܼܬܵܐ',
  'ܓܒ݂ܝܼܢܵܐ',
  'ܓܘܼܫܡܵܐ',
  'ܓܠܝܼܕܵܐ',
  'ܓܲܢ݇ܒܵܪܵܐ',
  'ܗܲܝܟܠܵܐ',
  'ܝܲܪܘܿܩܵܐ',
  'ܫܲܥܘܼܬ݂ܵܐ',
  'ܘܲܪܝܼܕ݂ܵܐ',
  'ܙܲܡܵܪܬܵܐ',
  'ܚܲܒܘܼܫܵܐ',
  'ܚܘܿܓ݂ܬܵܐ',
  'ܚܘܼܫܵܒ݂ܵܐ',
  'ܚܸܛܝܼܬ݂ܵܐ',
  'ܚܸܙܘܵܢܵܐ',
  'ܚܸܟ݂ܡܬ݂ܵܐ',
  'ܚܲܝܘܼܬ݂ܵܐ',
  'ܛܘܼܦ̮ܣܵܐ',
  'ܝܵܘܡܵܢܹ̈ܐ',
  'ܝܲܡܝܼܢܵܐ',
  'ܓܵܫܘܿܫܵܐ',
  'ܟܲܘܟ݂ܒ݂ܵܐ',
  'ܟܬ݂ܵܒ݂ܬܵܐ',
  'ܫܘܼܓ݂ܠܵܐ',
  'ܠܝܼܛܪܵܐ',
  'ܐ݇ܚܝܵܢܵܐ',
  'ܡܲܒܘܼܥܵܐ',
  'ܡܵܘܒܠܵܐ',
  'ܡܵܘܠܵܕ݂ܵܐ',
  'ܡܝܼܛܪܵܐ',
  'ܡܛܲܠܬ݂ܵܐ',
  'ܡܲܡܠܠܵܐ',
  'ܡܲܠܦܵܢܵܐ',
  'ܡܲܣܵܐ̈ܬ݂ܵܐ',
  'ܡܸܢܝܵܢܵܐ',
  'ܡܲܥܲܠܬܵܐ',
  'ܡܲܦܲܩܬܵܐ',
  'ܡܪܵܪܬܵܐ',
  'ܡܪܲܟܒ݂ܵܐ',
  'ܢܘܼܗܪܵܐ',
  'ܢܵܡܘܿܣܵܐ',
  'ܚܘܼܡܫܵܐ',
  'ܢܸܩܒܬ݂ܵܐ',
  'ܣܘܼܕܵܪܵܐ',
  'ܣܘܼܟܵܠܵܐ',
  'ܣܵܘܟܵܢܵܐ',
  'ܣܘܼܣܬܵܐ',
  'ܣܟܝܼܢܵܐ',
  'ܟܘܼܢܵܝܵܐ',
  'ܣܥܲܪܬܵܐ',
  'ܣܲܪܛܵܢܵܐ',
  'ܚܠܘܼܠܵܐ',
  'ܟܪܵܦܬܵܐ',
  'ܠܡܹܐܢܵܐ',
  'ܥܲܩܸܪܒ݂ܵܐ',
  'ܥܲܪܦܸܠܵܐ',
  'ܦܵܣܘܿܩܵܐ',
  'ܓܵܘܢܵܢܵܐ',
  'ܦܵܬܘܿܪܵܐ',
  'ܡܲܥܒܪܵܐ',
  'ܨܸܒܥܬ݂ܵܐ',
  'ܨܘܼܪܬܵܐ',
  'ܨܠܝܼܒ݂ܵܐ',
  'ܙܵܘܝܼܬ݂ܵܐ',
  'ܩܒ݂ܘܼܪܵܐ',
  'ܩܘܼܪܒܵܐ',
  'ܩܵܘܡܬ݂ܵܐ',
  'ܩܲܝܢܵܝܵܐ',
  'ܩܵܢܘܿܢܵܐ',
  'ܩܲܫܝܼܫܵܐ',
  'ܥܵܡܘܿܪܵܐ',
  'ܙܵܟ݂ܘܼܬ݂ܵܐ',
  'ܐܲܬܘܼܢܵܐ',
  'ܐܲܒ݂ܵܗܵܬܹ̈ܐ',
  'ܪܘܼܒ݂ܥܵܐ',
  'ܟܘܼܬܵܪܵܐ',
  'ܕܘܼܓܠܵܐ',
  'ܩܠܹܝܡܵܐ',
  'ܢܘܼܨܦܵܐ',
  'ܢܘܼܓ݂ܗܵܐ',
  'ܐܵܪܘܿܢܵܐ',
  'ܫܘܼܚܕܵܐ',
  'ܫܵܒ݂ܘܿܥܵܐ',
  'ܪܩܝܼܥܵܐ',
  'ܫܲܪܒܬܵܐ',
  'ܝܵܠܘܿܦܵܐ',
  'ܫܵܪܘܼܬܵܐ',
  'ܫܪܵܝܬܵܐ',
  'ܬܲܘܪܬܵܐ',
  'ܬܲܢܘܼܪܵܐ',
  'ܬܲܢܝܼܢܵܐ',
  'ܬܲܪܡܵܠܵܐ',
  'ܬܲܪܘܵܕ݂ܵܐ',
  'ܘܲܙܝܼܪܵܐ',
  'ܡܲܣܪܩܵܐ',
  'ܚܙܘܼܪܵܐ',
  'ܚܵܢܘܼܬܵܐ',
  'ܕܸܦܬܵܪܵܐ',
  'ܡܲܥܡܪܵܐ',
  'ܚܘܼܠܵܦܵܐ',
  'ܢܘܼܩܙܵܐ',
  'ܬܪܘܼܨܵܐ',
  'ܫܘܼܪܵܝܵܐ',
  'ܡܲܬ݂ܩܵܠܵܐ',
  'ܡܘܼܕܵܟ݂ܵܐ',
  'ܒܘܼܫܵܠܵܐ',
  'ܣܘܼܠܵܩܵܐ',
  'ܕܲܒܵܫܬܵܐ',
  'ܫܘܼܪܛܵܐ',
  'ܦܲܠܝܼܛܵܐ',
  'ܓܵܘܵܢܵܝܵܐ',
  'ܙܩܝܼܦܵܐ',
  'ܩܹܪܵܣܝܵܐ',
  'ܥܠܲܝܡܵܐ',
  'ܬܘܼܠܬ݂ܵܐ',
  'ܢܲܝܙܟ݂ܵܐ',
  'ܡܲܣܓܕ݂ܵܐ',
  'ܬܲܘܬܵܪܵܐ',
  'ܬܘܼܩܵܢܵܐ',
  'ܥܵܠܘܿܠܵܐ',
  'ܓܘܼܠܥܵܐ',
  'ܡܲܟ݂ܬܒ݂ܵܐ',
  'ܩܸܠܵܝܬܵܐ',
  'ܟܲܒܝܼܪܵܐ',
  'ܒܸܢܝܵܢܵܐ',
  'ܦܸܢܕܩܵܐ',
  'ܒܸܛܡܬ݂ܵܐ',
  'ܝܲܪܩܵܢܵܐ',
  'ܐܸܫܟܵܦܵܐ',
  'ܕܘܼܟܬ݂ܵܐ',
  'ܣܲܦ̮ܣܹܠܵܐ',
  'ܣܸܒܸܠܬܵܐ',
  'ܡܲܠܩܛܵܐ',
  'ܗܸܢܝܵܢܵܐ',
  'ܣܡܘܿܩܵܐ',
  'ܚܘܼܕܵܬܵܐ',
  'ܟܘܼܪܦܵܐ',
  'ܪܘܼܓ݂ܙܵܐ',
  'ܩܵܪܘܿܛܵܐ',
  'ܓܸܕܝܼܫܵܐ',
  'ܚܘܼܡܵܠܵܐ',
  'ܥܲܪܒ݂ܵܝܵܐ',
  'ܓܘܼܒܵܝܵܐ',
  'ܓܲܕܘܼܕܵܐ',
  'ܚܘܼܕܪܵܐ',
  'ܥܸܪܙܵܠܵܐ',
  'ܦܫܝܼܛܵܐ',
  'ܬܡܵܢܝܵܐ',
  'ܐܲܪܒܥܵܐ',
  'ܐܲܚܘܿܢܵܐ',
  'ܦܘܼܢܕܵܐ',
  'ܐܸܕܝܘܿܡ',
  'ܚܕ݂ܲܥܣܲܪ',
  'ܬܫܲܥܣܲܪ',
  'ܫܒ݂ܲܥܣܲܪ',
  'ܬܪܸܥܣܲܪ',
  'ܣܘܼܪܵܝܵܐ',
  'ܘܲܪܲܩܬܵܐ',
  'ܟ̰ܹܟܡܲܟ̰ܵܐ',
  'ܐ݇ܟܘܿܡܵܐ',
  'ܙܵܪܝܼܦܵܐ',
  'ܨܘܼܢܵܡܝܼ',
  'ܬܵܙܘܼܬܵܐ',
  'ܒܲܣܝܼܡܵܐ',
  'ܬܲܪܓܸܡܸܢ',
  'ܡܬܲܪܓܸܡ',
  'ܩܕܝܼܠܵܐ',
  'ܨܵܘܠܬܵܐ',
  'ܟܪܝܼܗܵܐ',
  'ܚܵܫܘܿܒ݂ܵܐ',
  'ܐܘܼܪܙܵܐ',
  'ܪܲܕܵܝܬܵܐ',
  'ܫܚܝܼܡܵܐ',
  'ܡܗܲܡܙܸܡ',
  'ܡܫܲܚܠܸܦ',
  'ܚܘܼܪܡܵܐ',
  'ܬܫܹܟ݂ܵܝܵܐ',
  'ܪܘܼܣܵܝܵܐ',
  'ܒܵܢܲܦ̮ܫܵܐ',
  'ܥܲܬܝܼܩܵܐ',
  'ܦ̮ܘܼܫܝܼܵܐ',
  'ܓܘܼܪܓ݂ܵܐ',
  'ܐܲܚܬܘܿܢ',
  'ܬܐܘܿܡܵܐ',
  'ܨܹܪܒܵܝܵܐ',
  'ܡܲܚܘܼܒܹܐ',
  'ܟ̰ܸܠܲܝܩܵܐ',
  'ܦ̮ܪܵܒ݂ܠܵܐ',
  'ܫܸܦܬܝܼܵܐ',
  'ܟܬ݂ܵܝܬܵܐ',
  'ܝܘܼܦܩܵܐ',
  'ܡܸܨܥܵܝܵܐ',
  'ܨܵܠܘܿܢ',
  'ܙܥܘܿܪܵܐ',
  'ܥܣܲܪܬܵܐ',
  'ܕܲܩܝܼܩܵܐ',
  'ܬܲܪܬܹܝܢ',
  'ܢܘܼܢܬܵܐ',
  'ܦܸܢܓܵܢܵܐ',
  'ܡܦܲܪܦܸܠ',
  'ܡܦܲܪܡܹܐ',
  'ܟܸܪܘܝܼܫ',
  'ܒܢܘܿܢܹ̈ܐ',
  'ܝܲܠܕܬܵܐ',
  'ܡܸܠܝܘܿܢ',
  'ܫܘܼܝܵܫܵܐ',
  'ܡܲܫܠܝܵܐ',
  'ܫܲܪܝܼܪܵܐ',
  'ܪܵܡܩܵܠܵܐ',
  'ܡܲܟ݂ܠܝܵܐ',
  'ܦܪܵܫܬܵܐ',
  'ܚܘܵܪܬܵܐ',
  'ܙܵܪܸܓܬܵܐ',
  'ܛܲܝܵܣܬܵܐ',
  'ܡܣܲܚܒܸܪ',
  'ܪܨܝܼܦܵܐ',
  'ܕܘܼܟܵܢܵܐ',
  'ܛܲܐܠܵܢܵܐ',
  'ܡܒܲܪܣܸܡ',
  'ܛܵܒ݂ܘܿܥܵܐ',
  'ܚܲܡܝܼܡܵܐ',
  'ܩܲܪܝܼܪܵܐ',
  'ܡܨܲܚܨܹܐ',
  'ܗܲܝܲܪܬܵܐ',
  'ܛܵܝܘܿܣܵܐ',
  'ܡܲܣܚܝܵܐ',
  'ܡܘܼܠܦܵܐ',
  'ܡܲܠܘܼܦܹܐ',
  'ܡܚܲܫܒܸܢ',
  'ܐܲܪܟܵܝܵܐ',
  'ܛܒ݂ܝܼܒ݂ܵܐ',
  'ܒܪܝܼܟܵܐ',
  'ܥܘܼܕܵܠܹܐ',
  'ܢܵܒ݂ܸܓܬܵܐ',
  'ܬܪܲܝܵܢܵܐ',
  'ܩܲܕ݇ܡܵܝܵܐ',
  'ܩܘܼܕܡܹܐ',
  'ܡܘܼܣܵܝܵܐ',
  'ܒܲܟ݂ܬܵܬܹ̈ܐ',
  'ܗܸܠܝܘܿܢ',
  'ܚܹܐܪܬܵܐ',
  'ܣܡܝܼܬܵܐ',
  'ܓܒ݂ܵܪܬܵܐ',
  'ܚܲܕ̈ܬܵܬܹܐ',
  'ܐܲܚܘܼܬܵܐ',
  'ܚܵܬܘܼܬܵܐ',
  'ܡܕܲܠܩܸܒ݂',
  'ܦܲܚܲܠܬܵܐ',
  'ܒܫܲܝܢܵܐ',
  'ܝܘܼܗ݇ܒ݂ܵܐ',
  'ܫܲܟܲܪܬܵܐ',
  'ܕܟ݂ܝܼܪܵܐ',
  'ܡܫܲܬܐܸܣ',
  'ܚܸܡܝܵܢܵܐ',
  'ܟܝܼܣܬܵܐ',
  'ܨܲܚܬܹܝܢ!',
  'ܡܒܲܪܒܸܙ',
  'ܡܒܲܙܒܸܙ',
  'ܡܓܲܪܓܸܫ',
  'ܡܲܫܪܝܵܐ',
  'ܐ݇ܚܵܪܵܝܵܐ',
  'ܓܵܪܘܿܪܵܐ',
  'ܡܲܩܠܝܵܐ',
  'ܗܵܘܵܝܬܵܐ',
  'ܕܲܪܵܓ݂ܬܵܐ',
  'ܚܲܒܝܼܒ݂ܵܐ',
  'ܩܢܲܫܬܵܐ',
  'ܫܲܒ݂ܥܝܼܢ',
  'ܥܲܙܝܼܙܵܐ',
  'ܣܸܡܲܠܬܵܐ',
  'ܛܘܼܝܵܣܵܐ',
  'ܫܘܝܼܬ݂ܵܐ',
  'ܡܒܲܕ݂ܒܸܕ݂',
  'ܡܙܲܡܙܸܡ',
  'ܛܫܸܗܝܵܐ',
  'ܡܬܲܩܬܸܩ',
  'ܩܲܕܝܼܡܵܐ',
  'ܒܪ̈ܲܚܡܹܐ!',
  'ܐܲܟܵܪܬܵܐ',
  'ܡ̣ܢ ܟܲܕ݂',
  'ܐܲܪܝܼܬܵܐ',
  'ܠܘܼܣܬܵܐ',
  'ܒܵܒܘܼܬܵܐ',
  'ܡܲܩܪܵܢܵܐ',
  'ܣܘܵܕܵܝܵܐ',
  'ܡܚܲܡܚܸܡ',
  'ܪܘܼܡܬܵܐ',
  'ܐܲܕ݇ܫܲܢ݇ܬ',
  'ܚܲܙܝܵܢܵܐ',
  'ܕܲܥܬܝܼܕ',
  'ܡܫܵܘܬܸܒ݂',
  'ܡܲܪܝܼܪܵܐ',
  'ܛܲܥܝܼܡܵܐ',
  'ܒܪܵܫܝܼܬ',
  'ܣܘܼܓܵܠܵܐ',
  'ܟܵܡܝܼܪܵܐ',
  'ܒܲܩܠܵܘܵܐ',
  'ܣܵܚܝܵܢܵܐ',
  'ܝܘܼܕܵܥܵܐ',
  'ܪܸܡܘܼܢܵܐ',
  'ܬܲܢܲܝܬܵܐ',
  'ܡܫܲܠܗܸܒ݂',
  'ܐܲܟܝܼܬܘܼ',
  'ܫܸܬܩܲܕ݇ܝ',
  'ܛܘܼܪܵܝܵܐ',
  'ܟ̰ܡܝܼܥܵܐ',
  'ܠܗܝܼܒ݂ܵܐ',
  'ܫܬܵܝܬܵܐ',
  'ܦܝܼܠܵܣܵܐ',
  'ܡܵܘܬܵܢܵܐ',
  'ܩܵܪܘܼܪܵܐ',
  'ܡܣܲܪܥܸܦ',
  'ܥܸܒ݂ܪܵܝܵܐ',
  'ܩܘܼܦܠܵܐ',
  'ܦܸܕ݂ܝܼܬ݂ܵܐ',
  'ܡܫܵܘܕܸܥ',
  'ܡܟܲܠܟܸܠ',
  'ܩܲܪܝܼܒ݂ܵܐ',
  'ܦܘܼܟܪܵܐ',
  'ܢܲܗܪܝܼܢ',
  'ܡܙܲܢܓܸܪ',
  'ܟܵܢܘܿܢܵܐ',
  'ܡܚܲܦܪܸܦ',
  'ܫܟܝܼܪܵܐ',
  'ܩܲܠܘܼܠܵܐ',
  'ܡܫܲܝܕ݂ܸܢ',
  'ܣܦܝܼܩܵܐ',
  'ܡܬܲܫܘܸܫ',
  'ܐܲܡܵܙܝܼܓ݂',
  'ܡܒܲܠܕܸܪ',
  'ܣܸܡܵܠܵܝܵܐ',
  'ܟܠܫܲܢ݇ܬ',
  'ܡܸܥܙܬܵܐ',
  'ܡܪܝܼܥܵܐ',
  'ܣܵܘܗܕܵܐ',
  'ܠܲܩܠܵܩܵܐ',
  'ܬܪܲܝܵܢܵܝ',
  'ܫܚܘܿܡܵܐ',
  'ܬܠܝܼܬܵܝ',
  'ܚܕ݂ܵܢܵܝܵܐ',
  'ܝܠܝܼܕܵܐ',
  'ܐܲܣܝܼܪܵܐ',
  'ܡܪܲܦܪܸܦ',
  'ܠܵܐ ܟܝܼ',
  'ܒܘܼܩܬܵܐ',
  'ܐܸܫܛܵܪܵܐ',
  'ܠܒ݂ܸܢ݇ܬܵܐ',
  'ܡܸܢ ܕ-',
  'ܡܲܦܬܵܚܵܐ',
  'ܓܡܲܠܬܵܐ',
  'ܡܘܼܚܸܒܵܐ',
  'ܝܵܘܢܵܝܵܐ',
  'ܦܵܪܣܵܝܵܐ',
  'ܡܩܲܪܩܸܪ',
  'ܡܩܲܛܩܸܛ',
  'ܗܹܡܸܙܡܵܢ',
  'ܡܫܲܥܡܸܪ',
  'ܟܠܵܢܵܝܵܐ',
  'ܨܵܪܘܿܚܵܐ',
  'ܡܟܲܫܟܸܫ',
  'ܚܘܼܠܵܢܵܐ',
  'ܠܘܼܘܵܝܵܐ',
  'ܛܲܒܵܢܓ̰ܵܐ',
  'ܟ̰ܘܿܬܩܵܐ',
  'ܝܲܠܦܘܼܟ',
  'ܫܲܦܝܼܪܵܐ',
  'ܦܝܼܬܣܵܐ',
  'ܣܘܼܦܪܵܐ',
  'ܣܡܝܼܟ݂ܵܐ',
  'ܚܲܕ݇ܟܡܵܐ',
  'ܡܸܠܝܵܪܕ݇',
  'ܟܝܵܢܵܝܵܐ',
  'ܨܝܼܢܵܝܵܐ',
  'ܡܬܲܢܬܸܢ',
  'ܩܲܢܝܼܬܵܐ',
  'ܙܵܥܘܿܩܵܐ',
  'ܥܲܬܝܼܪܵܐ',
  'ܡܟ̰ܲܙܟ̰ܸܙ',
  'ܡܬܲܠܬܵܐ',
  'ܡܪܲܬܪܸܬ',
  'ܡܙܲܥܙܸܥ',
  'ܓܘܼܒ݂ܪܵܐ',
  'ܒܸܪܩܵܝܵܐ',
  'ܝܘܼܪܟ݂ܵܐ',
  'ܡܝܲܩܪܵܐ',
  'ܡܸܨܪܵܝܵܐ',
  'ܐܝܼܡܲܝܠ',
  'ܟܲܗܪܵܒ݂ܵܐ',
  'ܟܟ̰ܝܼܚܵܐ',
  'ܡܐܲܠܥܵܐ',
  'ܬܸܪܨܵܢܵܐ',
  'ܗܹܪܘܿܡܵܐ',
  'ܐܲܪܒܥܵܬ݂',
  'ܩܵܦܘܿܚܵܐ',
  'ܡܩܲܪܡܸܟ̰',
  'ܒܸܪ̈ܟܵܟܹܐ',
  'ܒܘܿܡܒܵܐ',
  'ܡܲܐܬ݂ܹܝܢ',
  'ܬܡܵܢܝܵܬ݂',
  'ܬܸܠܦܵܦܹ̈ܐ',
  'ܐܲܠܦܹܝܢ',
  'ܫܘܼܬܬ݂ܵܐ',
  'ܢܣܝܼܪܵܐ',
  'ܡܸܬ݂ܚܙܹܐ',
  'ܡܚܲܝܕܵܐ',
  'ܘܫܲܪܟܵܐ',
  'ܬܘܼܓܵܓ݂ܵܐ',
  'ܟܲܠܝܼܦ̮ܵܐ',
  'ܣܝܼܢܲܡܵܐ',
  'ܡܲܒ݂ܩܠܵܐ',
  'ܝܲܬܘܼܡܵܐ',
  'ܣܒ݂ܝܼܗܵܐ',
  'ܡܣܲܩܠܸܩ',
  'ܠܲܩܵܕ݇ܡܵܐ',
  'ܡܫܲܡܫܸܢ',
  'ܡܲܢܕܵܝܵܐ',
  'ܡܸܚܕ݂ܵܕ݂ܹܐ',
  'ܐܸܚܕ݂ܵܕ݂ܹܐ',
  'ܡܣܲܙܓܸܪ',
  'ܡܩܲܦܣܵܐ',
  'ܣܢܝܼܩܵܐ',
  'ܥܲܠܐܲܣܵܣ',
  'ܡܚܲܪܛܵܐ',
  'ܡܓܲܪܒ݂ܸܢ',
  'ܫܲܠܝܼܛܵܐ',
  'ܐܲܒܝܼܫܵܐ',
  'ܡܣܲܡܣܸܡ',
  'ܗܲܕܝܼܐܵܐ',
  'ܚܘܼܪܓܵܐ',
  'ܝܲܡܡܵܝܵܐ',
  'ܣܲܡܪܵܢܵܐ',
  'ܡܚܲܡܫܵܐ',
  'ܒܣܲܒ݂ܪܵܐ',
  'ܐ݇ܚܹܪ݇ܢܵܐ',
  'ܡܫܵܘܚܸܪ',
  'ܟܸܪܟ݂ܵܢܵܐ',
  'ܡܛܲܪܛܸܡ',
  'ܝܲܪܝܼܟ݂ܵܐ',
  'ܚܲܬܝܼܬ݂ܵܐ',
  'ܠܸܗ݇ܘܵܝܵܐ',
  'ܒܕܘܼܟܲܬ݂',
  'ܡܘܼܢܵܚܵܐ',
  'ܕܘܼܢܝܹܐ',
  'ܟܦܲܦܬܵܐ',
  'ܣܸܢܣܵܪܵܐ',
  'ܪܵܓ݂ܘܿܠܵܐ',
  'ܩܘܼܬܝܼܵܐ',
  'ܩܹܒ݂ܘܿܬܵܐ',
  'ܪܹܝܫܵܝܵܐ',
  'ܬܲܚܬܵܝܵܐ',
  'ܪܲܚܝܼܩܵܐ',
  'ܕܵܡܝܵܢܵܐ',
  'ܡܕܲܡܕܸܡ',
  'ܡܐܲܝܟܵܐ',
  'ܒܚܲܪܬ݂ܵܐ',
  'ܟܪܝܼܒܵܐ',
  'ܒܵܠܘܿܢܵܐ',
  'ܡܘܲܙܘܸܙ',
  'ܣܵܡܘܿܵܝܵܐ',
  'ܡܚܲܕܬܵܐ',
  'ܠܵܥܘܿܣܵܐ',
  'ܡܲܚܡܠܵܐ',
  'ܡܟ̰ܲܩܟ̰ܸܩ',
  'ܟܲܪܣܵܢܵܐ',
  'ܚܘܼܝܵܨܵܐ',
  'ܥܲܡܡܵܝܵܐ',
  'ܣܘܼܝܵܟ݂ܵܐ',
  '-ܵܘܟ݂ܘܿܢ',
  'ܢܲܬܝܼܓ̰ܵܐ',
  'ܫܵܩܝܼܬ݂ܵܐ',
  'ܚܘܵܪܵܝܵܐ',
  'ܙܪܘܿܩܵܐ',
  'ܘܲܪܘܿܕ݂ܵܐ',
  'ܩܛܘܿܡܵܐ',
  'ܡܟܲܠܒܸܢ',
  'ܡܣܲܡܣܸܩ',
  'ܪܲܚܘܼܩܵܐ',
  'ܐܵܚܘܿܕ݂ܵܐ',
  'ܒܲܗܵܐܵܝܵܐ',
  'ܢܲܩܝܼܕ݂ܵܐ',
  'ܐܵܛܘܿܡܵܐ',
  'ܦܲܩܘܼܚܵܐ',
  'ܟܲܡܘܼܕܵܐ',
  'ܨܘܼܕܪܵܐ',
  'ܝܲܒܝܼܫܵܐ',
  'ܣܲܓܝܼܐܵܐ',
  'ܠܒܨܝܼܪ',
  'ܦܲܠܓܵܝܵܐ',
  'ܟܘܿܐܵܠܵܐ',
  'ܡܩܲܢܛܸܪ',
  'ܗܲܢܝܼܐܵܐ',
  'ܡܫܲܚܬܸܢ',
  'ܓ̰ܘܲܢܩܵܐ',
  'ܠܲܒܵܬ݇ܪܵܐ',
  'ܡܦܲܪܓܹܐ',
  'ܒܪ̈ܲܚܡܹܐ!',
  'ܝܲܩܘܼܪܵܐ',
  'ܢܲܥܝܼܡܵܐ',
  'ܓܵܘܵܐܝܼܬ݂',
  'ܢܸܫܝܵܢܵܐ',
  'ܡܫܵܘܬܸܦ',
  'ܒܲܠܚܘܿܕ݂',
  'ܒܗܸܦܟܵܐ',
  'ܚܘܼܨܵܪܵܐ',
  'ܗܘܼܦܵܟ݂ܵܐ',
  'ܥܲܬܝܼܕ݂ܵܐ',
  'ܨܒ݂ܘܼܬ݂ܵܐ',
  'ܡܲܛܒܚܵܐ',
  'ܡܲܛܥܵܡܵܐ',
  'ܪܲܛܝܼܒ݂ܵܐ',
  'ܙܹܠ݇ܡܘܼܢ',
  'ܥܲܡܘܼܩܵܐ',
  'ܣܩܘܿܡܵܐ',
  'ܡܲܒܢܝܵܐ',
  'ܨܵܘܒܵܝܵܐ',
  'ܫܸܪܫܵܝܵܐ',
  'ܐܲܡܝܼܢܵܐ',
  'ܙܠܝܼܡܵܐ',
  'ܦܬ݂ܝܼܚܵܐ',
  'ܐ݇ܢܵܫܵܝܵܐ',
  'ܪܲܕܘܼܕܵܐ',
  'ܕܡܝܼܟ݂ܵܐ',
  'ܒܫܝܼܠܵܐ',
  'ܗܵܘܢܵܢܵܐ',
  'ܚܲܟܝܼܡܵܐ',
  'ܚܲܣܝܼܢܵܐ',
  'ܙܲܕܝܼܩܵܐ',
  'ܚܠܝܼܡܵܐ',
  'ܚܲܡܘܼܨܵܐ',
  'ܡܲܠܘܼܚܵܐ',
  'ܬܹܝܡܘܼܢ',
  'ܦܲܪܠܵܡܵܢ',
  'ܝܲܨܝܼܦܵܐ',
  'ܝܲܬܝܼܪܵܐ',
  'ܝܩܝܼܕܵܐ',
  'ܒܨܝܼܪܵܐ',
  'ܟܢܝܼܫܵܐ',
  'ܟܦܝܼܢܵܐ',
  'ܟܬ݂ܝܼܒ݂ܵܐ',
  'ܡܸܚܕܵܝܵܐ',
  'ܫܠܝܼܨܵܐ',
  'ܦܫܝܼܡܵܐ',
  'ܪܵܡܘܿܙܵܐ',
  'ܡܵܘܥܕܵܐ',
  'ܡܬܲܚܡܸܢ',
  'ܦܫܝܼܩܵܐ',
  'ܟܘܼܫܵܝܵܐ',
  'ܥܠܘܿܝܵܐ',
  'ܫܹܐܪܬܵܐ',
  'ܡܕܝܼܢܹ̈ܐ',
  'ܫܒ݂ܵܒ݂ܵܝܵܐ',
  'ܩܵܐܹܡܵܝܵܐ',
  'ܥܝܵܕܵܝܵܐ',
  'ܚܲܪܝܼܦܵܐ',
  'ܢܵܨܪܵܝܵܐ',
  'ܡܲܫܵܚܲܛܵܐ',
  'ܡܚܲܪܡܸܛ',
  'ܡܙܵܘܓ݂ܵܐ',
  'ܐܘܼܦܩܵܐ',
  'ܥܲܘܩܵܢܵܐ',
  'ܐܲܨܠܵܝܵܐ',
  'ܡܝܲܬܪܵܐ',
  'ܢܘܼܣܟ݂ܵܐ',
  'ܬܲܦܢܟ݂ܵܐ',
  'ܦܵܝܫܵܢܵܐ',
  'ܡܬܲܦܢܸܟ݂',
  'ܩܘܼܪܨܵܐ',
  'ܚܵܫܘܿܠܵܐ',
  'ܢܵܣܘܿܟ݂ܵܐ',
  'ܚܠܝܼܨܵܐ',
  'ܣܒ݂ܝܼܪܵܐ',
  'ܚܲܫܝܼܫܵܐ',
  'ܚܵܫܘܿܫܵܐ',
  'ܒܪܲܡܫܵܐ',
  'ܐܸܨܛܲܚܸܢ',
  'ܫܢܝܼܙܵܐ',
  'ܙܓ݂ܝܼܪܵܐ',
  'ܕܪܵܥܢܵܐ',
  'ܡܓܲܠܓܸܠ',
  'ܡܟܲܪܡܸܟ݂',
  'ܬܟ݂ܝܼܠܵܐ',
  'ܣܘܼܟܪܵܐ',
  'ܐܵܬ݂ܝܵܢܵܐ',
  'ܕܝܼܵܬ݂ܹܩܹܐ',
  'ܕܘܼܪܵܪܵܐ',
  'ܟܵܒ݂ܘܿܫܵܐ',
  'ܫܵܢܝܵܢܵܐ',
  'ܡܲܬܝܼܢܵܐ',
  'ܟܘܼܫܦܵܐ',
  'ܚܠܝܼܦܵܐ',
  'ܠܸܚܵܝܦܵܐ',
  'ܘܵܠܝܵܝܵܐ',
  'ܩܝܼܪܵܛܵܐ',
  'ܬܠܵܬ݂ܝܼܢ',
  'ܥܸܣܪܝܼܢ',
  'ܬܸܫܥܝܼܢ',
  'ܐܸܫܬܝܼܢ',
  'ܬܡܵܢܝܼܢ',
  'ܚܲܡܫܝܼܢ',
  'ܓܘܼܒܬ݂ܵܐ',
  'ܕܵܘܟ݂ܠܵܐ',
  'ܡܓܲܪܓܸܡ',
  'ܡܲܓܵܢܵܝܵܐ',
  'ܬܪܝܼܨܵܐ',
  'ܦܘܼܬܵܚܵܐ',
  'ܚܵܕ݂ܸܪܘܵܢ',
  'ܡܒܲܨܒܸܨ',
  'ܦܠܲܢܹܛܵܐ',
  'ܙܡܝܼܢܵܐ',
  'ܚܲܡܘܼܕ݂ܵܐ',
  'ܐܘܼܫܦܵܐ',
  'ܪܘܼܦ̮ܫܵܐ',
  'ܩܵܡܘܿܙܵܐ',
  'ܡܫܲܡܗܵܐ',
  'ܡܢܲܣܝܵܐ',
  'ܫܸܡܚܵܢܵܐ',
  'ܝܲܐܝܼܒ݂ܵܐ',
  'ܒܵܬ݂ܲܪܟܸܢ',
  'ܩܘܼܠܵܥܵܐ',
  'ܥܸܒܘܿܝܵܐ',
  'ܡܥܲܘܩܵܐ',
  'ܚܲܠܲܩܬܵܐ',
  'ܡܸܬ݂ܟܬ݂ܸܒ݂',
  'ܡܚܲܙܕܸܓ',
  'ܓܘܼܢܚܵܐ',
  'ܡܓ̰ܲܢܓ̰ܸܪ',
  'ܡܓܲܢܓܸܙ',
  'ܫܘܼܪܒ݂ܵܐ',
  'ܡܗܲܘܢܸܢ',
  'ܗܲܘܝܼܐܵܐ',
  'ܒܲܗܘܼܪܵܐ',
  'ܕܘܼܪܬܵܐ',
  'ܫܠܝܼܡܵܐ',
  'ܡܨܲܢܥܵܐ',
  'ܩܵܐ ܕ-',
  'ܛܵܠܘܿܡܵܐ',
  'ܚܘܼܪܕܵܐ',
  'ܝܬܵܒ݂ܬܵܐ',
  'ܟܲܕܝܼܒ݂ܵܐ',
  'ܛܥܝܼܫܵܐ',
  'ܝܘܼܐܵܒ݂ܵܐ',
  'ܛܲܦܵܝܬܵܐ',
  'ܟܝܘܼܠܵܐ',
  'ܡܨܲܢܨܸܠ',
  'ܙܝܼܘܵܢܵܐ',
  'ܫܲܚܝܼܢܵܐ',
  'ܪܓ݂ܝܼܙܵܐ',
  'ܩܘܼܛܒܵܐ',
  'ܩܵܝܘܿܡܵܐ',
  'ܐܵܐܲܪܵܝܵܐ',
  'ܡܲܬ݂ܩܢܵܐ',
  'ܓܘܼܝܵܒ݂ܵܐ',
  'ܐܲܡܘܼܪܵܐ',
  'ܡܬܲܪܥܸܣ',
  'ܡܢܲܘܢܹܐ',
  'ܥܘܼܣܪܵܐ',
  'ܬܘܼܡܢܵܐ',
  'ܐܲܠܦܵܝܵܐ',
  'ܥܸܣܪ̈ܲܝܹܐ',
  'ܫܘܼܒ݂ܥܵܐ',
  'ܬܘܼܫܥܵܐ',
  'ܡܘܼܛܵܝܵܐ',
  'ܫܒ݂ܘܿܛܵܐ',
  'ܡܬܲܦܬܸܦ',
  'ܡܚܲܪܙܸܦ',
  'ܡܨܲܪܦܸܚ',
  'ܡܹܐܦܝܵܐ',
  'ܦܪܝܼܫܵܐ',
  'ܡܲܚܠܦܵܐ',
  'ܓܘܼܠܦܵܐ',
  'ܚ̈ܘܼܘܵܬ݂ܹܐ',
  'ܗܸܠܝܘܿܡ',
  'ܥܪܝܼܨܵܐ',
  'ܡܲܥܠܵܢܵܐ',
  'ܡܲܦܩܵܢܵܐ',
  'ܛܒ݂ܝܼܥܵܐ',
  'ܥܲܪܒܵܢܵܐ',
  'ܡܥܲܪܘܸܨ',
  'ܪܲܩܝܼܩܵܐ',
  'ܣܝܼܛܠܵܐ',
  'ܒܘܼܥܕܵܐ',
  'ܩܲܛܝܼܢܵܐ',
  'ܕܲܒܘܼܫܵܐ',
  'ܡܒܲܠܒܸܠ',
  'ܡܕܲܫܕܸܫ',
  'ܡܩܲܘܡܵܐ',
  'ܡܛܲܪܡܸܫ',
  'ܒܲܪܢܵܫܵܐ',
  'ܡܦܲܪܦܸܥ',
  'ܩܘܼܦܣܵܐ',
  'ܡܲܛܚܵܢܵܐ',
  'ܬܲܓܵܪܵܝܵܐ',
  'ܢܸܥܡܬ݂ܵܐ',
  'ܒܢܝܼܩܵܐ',
  'ܥܸܣܩܬ݂ܵܐ',
  'ܫܵܪܘܿܝܵܐ',
  'ܡܩܲܢܘܸܢ',
  'ܡܣܲܘܟܸܢ',
  'ܡܕܲܫ̃ܡܸܢ',
  'ܫܵܪܘܿܩܵܐ',
  'ܟܲܫܝܼܪܵܐ',
  'ܡܬܲܫܥܹܐ',
  'ܠܘܿܥܬܵܐ',
  'ܡܒܲܠܩܸܢ',
  'ܥܸܢܝܵܢܵܐ',
  'ܡܫܲܥܠܹܐ',
  'ܡܥܲܪܦܸܠ',
  'ܡܲܫܬܝܵܐ',
  'ܐܝܼܕ݂ܡܵܐ',
  'ܝܲܓ݂ܝܼܣܵܐ',
  'ܐܲܝܠܹܝܢ',
  'ܡܥܲܠܬܸܢ',
  'ܛܘܼܦܪܵܐ',
  'ܡܫܲܡܠܹܐ',
  'ܡܟ݂ܝܼܪܵܐ',
  'ܛܘܼܟܵܣܵܐ',
  'ܬܲܡܝܼܡܵܐ',
  'ܣܸܢܐܬ݂ܵܐ',
  'ܢܲܦܝܼܩܵܐ',
  'ܥܲܠܝܼܠܵܐ',
  'ܫܒ݂ܲܛܪܵܐ',
  'ܡܫܲܝܢܵܐ',
  'ܩܲܢܝܼܛܵܐ',
  'ܪܵܘܒܵܢܵܐ',
  'ܩܵܛܘܿܢܵܐ',
  'ܫܟ݂ܝܼܚܵܐ',
  'ܒܲܛܝܼܠܵܐ',
  'ܦܲܥܝܼܠܵܐ',
  'ܡܣܲܩܒܸܠ',
  'ܢܲܨܝܼܚܵܐ',
  'ܕܲܪܘܵܙܵܐ',
  'ܡܲܣܡܟ݂ܵܐ',
  'ܝܵܠܸܚܬܵܐ',
  'ܬܘܼܩܦܵܐ',
  'ܓܵܪܘܿܡܵܐ',
  'ܬܲܠܝܼܠܵܐ',
  'ܣܵܝܘܿܡܵܐ',
  'ܡܣܲܪܗܸܒ݂',
  'ܓܠܝܼܙܵܐ',
  'ܡܲܪܫܡܵܐ',
  'ܓܘܼܬܵܬ݂ܹ̈ܐ',
  'ܦܲܝܘܼܚܵܐ',
  'ܓܠܘܿܠܵܐ',
  'ܒ̮ܝܼܕܝܘܿ',
  'ܗ̇ܘ ܚܲܕ݇',
  'ܦ̮ܝܼܠܡܵܐ',
  'ܩܸܛܥܵܢܵܐ',
  'ܚܒ݂ܝܼܨܵܐ',
  'ܚܵܘܒܬ݂ܵܐ',
  'ܡܠܝܼܠܵܐ',
  'ܡܗܝܼܪܵܐ',
  'ܒܚܲܝܠܵܐ',
  'ܡܚܝܼܠܵܐ',
  'ܙܪܝܼܙܵܐ',
  'ܓܵܨܘܿܨܵܐ',
  'ܒܲܪܒܵܒ݂ܵܐ',
  'ܚܲܒܘܿܛܵܐ',
  'ܒܨܲܦܪܵܐ',
  'ܡܫܲܪܒܸܛ',
  'ܚܸܠܘܿܝܵܐ',
  'ܛܡܘܿܡܵܐ',
  'ܚܲܫܘܼܟܵܐ',
  'ܒܵܒ݂ܘܿܢܵܐ',
  'ܡܚܲܪܒܸܫ',
  'ܗܡܲܝܪܵܐ',
  'ܒܲܣܝܼܪܵܐ',
  'ܒܘܼܪܨܵܐ',
  'ܩܘܼܒܵܠܵܐ',
  'ܫܸܦܘܿܠܵܐ',
  'ܒܠܝܼܓ݂ܵܐ',
  'ܢܸܙܠܬ݂ܵܐ',
  'ܓܸܪܘܿܕ݂ܵܐ',
  'ܪܲܓܘܼܫܵܐ',
  'ܒܲܢܙܝܼܢ',
  'ܚܵܟ݂ܘܿܟ݂ܵܐ',
  'ܚܘܼܟܵܟ݂ܵܐ',
  'ܩܘܼܪܛܵܐ',
  'ܠܘܼܩܵܛܵܐ',
  'ܡܙܲܪܙܸܛ',
  'ܣܘܼܪܦܵܐ',
  'ܐܵܦ ܚܲܕ݇',
  'ܐ݇ܙܵܠܬܵܐ',
  'ܚܵܫܚܵܝܵܐ',
  'ܐܵܦ ܠܵܐ',
  'ܫܓ݂ܝܼܡܵܐ',
  'ܐܵܠܨܵܝܵܐ',
  'ܠܵܚܡܵܝܵܐ',
  'ܩܪܝܼܚܵܐ',
  'ܬܘܼܬܵܢܵܐ',
  'ܪܬ݂ܝܼܚܵܐ',
  'ܐ݇ܬ݂ܵܝܬܵܐ',
  'ܫܵܓ݂ܘܿܡܵܐ',
  'ܡܫܲܪܬܸܚ',
  'ܦܸܠܥܵܢܵܐ',
  'ܢܵܛܘܿܪܵܐ',
  'ܒܵܪܸܩܬܵܐ',
  'ܚܫܸܠܬܵܐ',
  'ܟܘܼܚܬܵܐ',
  'ܡܲܕܵܠܝܵܐ',
  'ܙܘܼܢܵܪܵܐ',
  'ܥܒ݂ܝܼܕ݂ܵܐ',
  'ܦ̮ܝܼܙܝܼܵܐ',
  'ܣܦܝܼܪܵܐ',
  'ܥܵܘܝܡܵܐ',
  'ܡܫܵܓ݂ܬܵܐ',
  'ܛܘܼܝܵܠܵܐ',
  'ܣܸܛܪܵܝܵܐ',
  'ܡܫܲܩܫܸܩ',
  'ܡܫܲܥܒܸܕ݂',
  'ܟܘܼܪܚܵܐ',
  'ܫܝܼܪܵܝܵܐ',
  'ܨܘܼܒ݂ܥܵܐ',
  'ܛܵܝܸܠܬܵܐ',
  'ܢܵܟ݂ܘܿܦܵܐ',
  'ܡܦܲܪܦܸܫ',
  'ܡܬܲܦ̮ܬܸܫ',
  'ܟܸܣܘܿܝܵܐ',
  'ܟܵܪܘܿܟ݂ܵܐ',
  'ܝܵܪܘܿܬ݂ܵܐ',
  'ܚܵܙܘܿܩܵܐ',
  'ܫܲܒܘܼܪܵܐ',
  'ܙܘܼܗܵܪܵܐ',
  'ܟܘܼܠܒܵܐ',
  'ܝܲܩܪ̈ܵܬ݂ܹܐ',
  'ܕܘܼܝܵܩܵܐ',
  'ܣܘܼܪܵܩܵܐ',
  'ܡܲܣܪܛܵܐ',
  'ܡܕܲܩܕܸܩ',
  'ܫܘܼܪܬ݂ܵܐ',
  'ܦܸܣܬܩܵܐ',
  'ܫܘܼܠܦܵܐ',
  'ܚܘܼܫܬܵܐ',
  'ܡܫܲܪܫܸܪ',
  'ܟܢܝܼܙܵܐ',
  'ܚܠܘܿܨܵܐ',
  'ܕܥܝܼܟ݂ܵܐ',
  'ܡܙܝܼܕ݂ܵܐ',
]
export const DEFINITIONS = [
  'dog keeper, dog trainer; one who hunts with dogs',
  'letter, character',
  'sock',
  'revelation',
  'benediction',
  'south (compass point, 180° directed toward the South Pole)',
  'west, occident (the direction of the setting sun at an equinox)',
  'north (compass point, 0° directed toward the North Pole)',
  'east, orient',
  'that which is due or right; what must be done',
  'wheel (especially of a carriage, well, or mill)',
  'plural of ܐܝܼܕ݂ܲܥܬܵܐ (īḏaˁtā, “knowledge”)',
  'alchemy',
  'machine, device, engine',
  'floor, storey, level',
  'zodiac sign, horoscope',
  'storm, gale, gust',
  'melon (large round fruit with sweet pulpy flesh and many seeds)',
  'pepper, fruit of the capsicum',
  'rust, aerugo, tarnish, verdigris, patina',
  'idea, notion, thought',
  'spring (season when plants bloom and dormant animals spring to life)',
  'demoniac, possessed person',
  'question, inquiry, query',
  'letter, epistle',
  'road, way, path',
  'tree',
  'daytime (the time of the day between sunrise and sunset)',
  'square, quadratic',
  'woman, wife',
  'bond, tie, chain',
  'female equivalent of ܐܵܣܝܵܐ (āsyā, “medical doctor, healer, physician, nurse”)',
  'plural of ܐܲܪܥܵܐ (arˁā)',
  'you, ye, yall, you guys',
  'you, ye, yall',
  'rabbit, hare',
  'egg',
  'oak',
  'son (boy or man in relation to his parents)',
  'creation',
  'eyebrow',
  'body',
  'ice (water in its frozen state, a brittle transparent crystalline solid)',
  'strong man',
  'temple',
  'green',
  'yellow',
  'vein, artery, nerve',
  'singer, musician',
  'apple',
  'dial',
  'thought',
  'grain of wheat',
  'show, spectacle',
  'wisdom, knowledge, science, philosophy',
  'life, lifestyle, lifespan',
  'example, symbol, allegory',
  'plural of ܝܵܘܡܵܐ (yāwmā, “day”)',
  'right direction',
  'spy',
  'celestial body (especially star)',
  'verbal noun of ܟܵܬ݂ܹܒ݂ (kāṯēḇ)',
  'job, work, labor, occupation',
  'litre',
  'relative, kinsman, kin',
  'spring, fount, fountain',
  'burden, load, cargo',
  'birth, nativity (instance of childbirth and beginning of life)',
  'metre/meter',
  'awning, shade, alcove, canopy',
  'speech, talk, discourse, diction',
  'teacher, instructor',
  'balance, scales',
  'number, numeral, figure',
  'entry, log, datum, record',
  'version, edition',
  'gall bladder',
  'complicated, complex',
  'light, brightness, brilliance',
  'constitution',
  'fifth (⅕)',
  'female',
  'order, arrangement, layout',
  'meaning, definition, sense',
  'helm, rudder of a ship',
  'feminine of ܣܘܼܣܹܐ (sūsē): horse mare',
  'knife, dagger',
  'surname, family name, last name',
  'single hair, strand of hair',
  'crab (crustacean found chiefly on seashores)',
  'wedding feast (ritual or feast officially celebrating the beginning of a marriage)',
  'furnishing, a piece of furniture',
  'harbor/harbour, port haven',
  'scorpion',
  'fog, mist, cloud',
  'cutter',
  'colorful',
  'table',
  'crossing, passage, traverse',
  'finger, toe, digit',
  'image, picture',
  'crucifix, cross',
  'external corner, edge',
  'grave',
  'nearness, proximity, vicinity',
  'height, stature',
  'smith',
  'law, canon',
  'old, elderly, aged',
  'resident, inhabitant, dweller',
  'victory, triumph',
  'furnace; oven',
  'plural of ܐܲܒ݂ܵܐ (aḇā); forefathers, ancestors',
  'fourth, quarter (¼)',
  'continuation',
  'lie',
  'region, climate, clime (geographical area of the earth, particularly a zone of latitude)',
  'dusk (period of time at the end of day when sun is below the horizon but before full onset of night)',
  'dawn (first appearance of light in the sky before sunrise)',
  'wardrobe, closet (piece of furniture for storing clothes)',
  'bribe',
  'week, period of seven days',
  'firm, compact',
  'generation, genealogy',
  'student, pupil',
  'breakfast (first meal of the day, eaten in the morning)',
  'solution, answer to a problem',
  'feminine of ܬܵܘܪܵܐ (tāwrā): cow',
  'oven, tandoor (chamber used for baking or heating)',
  'dragon; sea serpent, monster',
  'wallet, purse',
  'spoon (scooped utensil for eating or serving)',
  'minister, cabinet minister',
  'rake',
  'pig, swine, hog',
  'tavern, pub, inn',
  'notebook, copybook, exercise book',
  'abode, dwelling',
  'exchange, replacement',
  'point, dot',
  'verily, truly, indeed, in fact',
  'beginning, origin',
  'balance, scales',
  'mixture, compound, preparation',
  'cooking',
  'ascent',
  'bee',
  'royal guard, city watchman',
  'safe, saved, protected',
  'general, common, universal',
  'crucified',
  'cherry',
  'young, youthful, juvenile',
  'third (⅓)',
  'spear, lance, javelin',
  'mosque, masjid',
  'excess, remainder, rest, surplus, leavings',
  'technology, tools, instruments, apparatus',
  'street (road in a village or town usually with sidewalks, pavements and buildings)',
  'edible seed',
  'office, bureau (room or building used for writing and non-manual work)',
  'cell, alcove (especially that of a monk)',
  'great, large, big, massive',
  'building, edifice',
  'hotel (establishment that provides accommodation and other services for paying guests)',
  'terebinth',
  'jaundice; chlorosis, greensickness',
  'shoemaker, cobbler (worker who makes or repairs shoes)',
  'place, location, position',
  'railing, hand railing, guard railing',
  'staircase, stairway (flight of stairs)',
  'forceps',
  'use, utility',
  'red',
  'update',
  'viper',
  'anger, wrath, fury',
  'cartilage',
  'heap, pile, sheaf, stock',
  'collection, gathering',
  'Arab',
  'choice, selection',
  'bachelor, unmarried man',
  'circle',
  'portable bed (often situated on roofs or in fields)',
  'simple, clear, obvious, straightforward',
  'eight (8)',
  'four (4)',
  'little brother',
  'candle (light source consisting of a wick embedded typically in wax)',
  'today',
  'eleven (11)',
  'nineteen (19)',
  'seventeen (17)',
  'twelve (12)',
  'Assyrian, Syriac: A person of Assyrian descent',
  'document',
  'glove compartment',
  'black',
  'giraffe',
  'tsunami, tidal wave (large and destructive ocean wave)',
  'freshness: The state or quality of being fresh.',
  'pleasant',
  'first-person singular masculine present indicative of ܬܲܪܓܸܡ (targim)',
  'to translate (express the sense of words or text in another language)',
  'key (device used to open and close a lock)',
  'shoe (protective covering for the foot)',
  'ill, sick (in poor health)',
  'computer (programmable electronic device)',
  'guy, youth, young man',
  'car, automobile (passenger vehicle steered by a driver)',
  'dusky, swarthy',
  'to speak, talk',
  'to change, alter, edit',
  'date fruit and tree',
  'Czech, Czechian, Czechic, Czechish (of, from, or pertaining to Czechia)',
  'Russian (of, or pertaining to Russia)',
  'violet (purple or blue flower from temperate regions)',
  'old, antique',
  'fuchsia',
  'past participle of ܓܲܪܸܓ݂ (garriḡ)',
  'Alternative form of ܐܲܢ݇ܬܘܿܢ (atōn, “you, yall, you guys”)',
  'one who was born of a multiple birth, i.e. a twin, triplet, quadruplet, etc.',
  'Serbian (from Serbia or pertaining to its culture, people, language)',
  'present participle of ܡܲܚܸܒ (maḥḥib)',
  'strawberry: a sweet soft red fruit with a seed-studded surface.',
  'strawberry (sweet soft red fruit with a seed-studded surface)',
  'watermelon (large fruit with smooth green skin, red pulp, and watery juice)',
  'chicken, hen',
  'skirt (article of clothing that hangs from the waist and covers the lower part of the body)',
  'middle, central',
  'living room, sitting room (room in a house where people pass the time in leisure)',
  'small, little',
  'decade (period of ten years)',
  'short, small, tiny',
  'feminine of ܬܪܹܝܢ (trēn, “two”)',
  'feminine of ܢܘܼܢܵܐ (nūnā): fish',
  'teacup (small cup, normally sitting in a saucer as part of a tea set, commonly used for drinking tea)',
  'to beseech, supplicate, implore, call upon; to beg, plea',
  'to understand, comprehend',
  'rabbit',
  'plural of ܒܪܘܿܢܵܐ (brōnā): sons',
  'girl, female child',
  'million (1,000,000; 10⁶)',
  'politics',
  'fork (a pronged tool for use on farms or in gardens for weeding or turning over the soil)',
  'true, real, factual',
  'speaker, loudspeaker',
  'stop, stand, stopping place, stopover',
  'instance of separating, manner of separating; a separation',
  'feminine of ܚܘܵܪܵܐ (ḳwara)',
  'feminine of ܙܵܪܓܵܐ (zarga)',
  'aeroplane, airplane, plane (powered flying vehicle with fixed wings and a weight greater than that of the air it displaces)',
  'to visit',
  'platform, pier, wharf, quay',
  'shop, store (establishment that sells goods)',
  'player (person who plays or is playing a given game or sport)',
  'to smile',
  'printer (operator of a printing press or the owner of a printing business)',
  'hot (relating to having or giving off a high temperature)',
  'cold, frigid',
  'to examine, test, review',
  'help, aid, assistance, relief',
  'pilot, aviator (controller of an aircraft)',
  'swimming pool (pool of water used for swimming, usually one which has been artificially constructed)',
  'past participle of ܡܲܠܸܦ (mallip); taught',
  'present participle of ܡܲܠܸܦ (mallip)',
  'to conclude, suppose, reckon',
  'librarian, library-keeper (keeper or manager of a library)',
  'famous (known about by many people)',
  'past participle of ܒܵܪܹܟ (bārēk, “to kneel”)',
  'Alternative form of ܐܸܚܕ݂ܵܕ݂ܹܐ (iḥḏāḏē, “one another, each other”)',
  'granddaughter (daughter of someones child)',
  'second',
  'first (1st)',
  'tomorrow',
  'laundry (that which needs to be, is being, or has been laundered).',
  'plural of ܒܲܟ݂ܬܵܐ (baḵtā, “woman, wife”)',
  'asparagus (Asparagus gen. et spp.)',
  'feminine of ܚܹܐܪܵܐ (ḥērā)',
  'feminine of ܣܸܡܝܵܐ (simyā)',
  'instance of marrying',
  'feminine plural of ܚܲܕܬܵܐ (ḥadtā, “new”)',
  'brotherhood, fraternity',
  'sisterhood, sorority',
  'to oppose, be against',
  'pardon, forgiveness',
  'hello, welcome (usually in response of ܫܠܵܡܵܐ (šlāmā))',
  'past participle of ܝܵܗ݇ܒ݂ܹܠ (yāḇēl)',
  'thankfulness, gratitude',
  'past participle of ܕܵܟ݂ܹܪ (dāḵēr): remembered',
  'to found, establish, set up',
  'father-in-law (father of one’s spouse)',
  'small bag',
  'bon appétit, enjoy your meal (used to wish someone enjoyment of the meal they are about to eat)',
  'to scatter, to disperse',
  'to spurt, to squirt, to spray everywhere',
  'to drag',
  'station (bus, railway, radio etc.)',
  'last, final, ultimate',
  'puller, tower, drawer',
  'frying pan, pan (long-handled shallow pan used for frying food)',
  'feminine of ܗܵܘܵܝܵܐ (hāwāyā, “aerial”)',
  'feminine of ܕܲܪܵܓ݂ܵܐ (darāḡā, “cyclist”)',
  'beloved',
  'earring (piece of jewelry worn on the ear)',
  'seventy (70)',
  'powerful, mighty',
  'ladder (frame used for ascent and descent, consisting of two side pieces and fastened rungs acting as stairs)',
  'flight (trip made by an aircraft, particularly one between two cities or countries, which is often planned or reserved in advance.)',
  'feminine of ܫܸܘܝܵܐ (šiwyā, “equal, even”)',
  'to disturb',
  'to buzz, to ring',
  'Alternative form of ܛܸܫܝܵܐ (ṭišyā, “hidden, hiding”)',
  'to knock',
  'previous, preceding, prior, old (of an earlier time)',
  'bless you (said to someone who has just sneezed or coughed as a polite remark)',
  'female equivalent of ܐܲܟܵܪܵܐ (akkārā): farmer',
  'from the time that, since, ever since',
  'female equivalent of ܐܲܪܝܵܐ (aryā) lioness',
  'vulva (the external female sexual organ)',
  'fatherhood, paternity',
  'cooler (anything which cools)',
  'conversational',
  'to be/become fevered, to have a fever',
  'hill',
  'this year',
  'viewer (someone who views a spectacle or television)',
  'future (the time ahead; those moments yet to be experienced)',
  'to retire (to stop working on a permanent basis, usually because of old age or illness)',
  'bitter (in taste)',
  'tasty (having a pleasant taste)',
  'in the beginning',
  'recording (reproduction of sound, video, etc. stored in a permanent medium)',
  'camera',
  'baklava (popular sweet pastry found in many cuisines of the Middle East and the Balkans)',
  'swimmer (person who swims, especially one who participates in swimming competitions)',
  'media',
  'pomegranate (spherical fruit with a tough golden-orange outer skin and sweet red gelatinous flesh containing many seeds)',
  'verbal noun of ܡܬܲܢܹܐ (mtannē, “to recite, recount”) recounting',
  'to be/become inflamed',
  'Akitu (the Assyrian-Babylonian new year festival celebrated annually on the first of April)',
  'last year',
  'mountainous',
  'turned off, switched off, off',
  'turned on, switched on, on, lit, burning',
  'instance of drinking; a drink',
  'bowl (vessel used to hold mix or present food)',
  'plague, pestilence (highly contagious, infectious, virulent and devastating epidemic disease)',
  'vial (glass vessel or bottle, especially a small tube-shaped bottle used to store medicine, perfume or other chemicals)',
  'to germinate',
  'Hebrew (of or pertaining to Hebrew people or language)',
  'lock, bolt',
  'sleeve (especially a long sleeve)',
  'to signify, symbolize',
  'to ululate (to emit a series of high-pitched noises, done by Middle Eastern women at times of celebration)',
  'near, nigh',
  'cork, bottle cap',
  'absolute plural form of ܢܲܗܪܵܐ (nahrā, “river”)',
  'to ring, chime',
  'brazier, hearth',
  'to dig intensely, to ravage a place through digging',
  'thanks to',
  'light, lightweight',
  'to be crazy, to act foolishly',
  'empty',
  'to whisper',
  'Amazigh, Berbers, the Berber peoples',
  'to mail, post',
  'leftist',
  'annually, yearly, every year, each year',
  'single hair',
  'ill, sick (in poor health)',
  'enthusiasm, passion',
  'stork',
  'construct state singular of ܬܪܲܝܵܢܵܝܵܐ (trayyānāyā, “singular”)',
  'brown',
  'construct state singular of ܬܠܝܼܬܵܝܵܐ (tlītāyā, “singular”)',
  'singular (being only one of a larger population)',
  'past participle of ܝܵܠܹܕ (yālēd, “to birth”)',
  'prisoner',
  'to wave (to move back and forth repeatedly and somewhat loosely)',
  'I don’t know',
  'doll, puppet, marionette (toy in the form of a human, usually of a baby or girl)',
  'document (original or official paper)',
  'brick (hardened block used for building)',
  'since, because',
  'switch (device to turn electric current on and off)',
  'female equivalent of ܓܲܡܠܵܐ (gamlā, “camel, dromadery”)',
  'loved, beloved',
  'Greek (of, from, or pertaining to Greece, the Greek culture or Greek people)',
  'Persian (of, from, or pertaining to Persia, the Persian culture or Persian people)',
  'to rumble',
  'to cut up, to chop up',
  'word (unit of language)',
  'to colonize',
  'whole, entire, overall',
  'rocket',
  'to drive an animal away, to shoo',
  'tunnel (underground or underwater passage)',
  'funeral',
  'pistol, revolver, handgun',
  'brush (tool of flexible bristles attached to a handle for cleaning, sweeping and arranging hair)',
  'hand-held fan',
  'beautiful, pretty, handsome, fair, lovely',
  'pizza',
  'dining table, table board',
  'certain, sure, definite',
  'few, some (an indeterminate small number)',
  'milliard, billion (1,000,000,000; 10⁹)',
  'natural',
  'Chinese (of, from, or pertaining to China, its culture or people)',
  'to smoke, emit smoke',
  'channel, canal',
  'shouting, calling loudly',
  'rich, wealthy, well off',
  'to sizzle (to make the sound of water boiling on a hot surface)',
  'triple, threefold',
  'to tremble',
  'to vibrate',
  'Alternative form of ܓܘܼܪܵܐ (gūrā, “great, big, large”)',
  'electric, electrical (of or pertaining to electricity, or powered by electricity)',
  'length',
  'Sir, Mister (a title of respect for a man, preceding his surname)',
  'Egyptian (of, from, or pertaining to Egypt, the Egyptian culture, the Egyptian people or Egyptian language)',
  'email',
  'amber',
  'tired, worn out (in need of rest or sleep)',
  'ribbed, corrugated',
  'direction, orientation, heading (indication of the point toward which an object is moving)',
  'perfume, fragrance, scent, aroma',
  'masculine construct state of ܐܲܪܒܥܵܐ (arbˁā, “four”)',
  'brake (device used to slow or stop a vehicle or machine)',
  'to crease',
  'plural of ܒܸܪܟܵܐ (birkā, “knee”)',
  'bomb',
  'dual of ܡܐܵܐ (mˀā), two hundred',
  'masculine construct state of ܬܡܵܢܝܵܐ (tmānyā, “eight”)',
  'plural of ܬܸܠܦܵܐ (tilpā, “eyelid, eyelash”)',
  'dual of ܐܲܠܦܵܐ (alpā), two thousand',
  'sixth (⅙)',
  'prism (polyhedron with parallel ends of the same size and shape)',
  'to be seen, visible',
  'united, unified',
  'etc., et cetera, and so on',
  'coronation',
  'caliph',
  'of a person: humorous or amusing, either characteristically or on a particular occasion.',
  'supermarket (large self-service store that sells groceries, medications, household goods and/or clothing)',
  'orphan',
  'parallel',
  'to climb',
  'ahead',
  'to be/become sunny',
  'Mandaean, Sabian',
  'together',
  'one another, each other',
  'to agree',
  'cubic, cuboid',
  'lacking, needful, needing, in need',
  'supposedly, as if',
  'conical, conal',
  'to become foul, dirty',
  'ruling, governing',
  'raisin',
  'to cure, remedy, heal, restore',
  'pacific, peaceful, tranquil, calm, serene',
  'step- used to indicate that the person being identified is not a blood relative but is related through the marriage of a parent',
  'marine, maritime (of or pertaining to the sea)',
  'dark-skinned',
  'pentagonal',
  'to hope (with ܕ- (d-))',
  'other, another, one more, else',
  'to delay; to be late; to remain behind',
  'engine, motor',
  'to grumble, complain, murmur with discontent',
  'long (having a great distance from one end to the other)',
  'certain, sure, definite',
  'infinitive of ܗܵܘܹܐ (hāwē, “to be”).',
  'instead of, in lieu of, in place of',
  'climate (long term atmospheric conditions)',
  'world, this mortal life; life in this world',
  'glove',
  'backbone, spine',
  'valley',
  'small box, container, canister',
  'box, ark, chest',
  'main, principal',
  'lower, lowermost, inferior',
  'far, distant; remote',
  'resembling, similar, alike, analogous',
  'to bleed',
  'from where, whence',
  'in the following, then',
  'angry, furious, enraged, vexed',
  'balloon (inflatable and buoyant object; child’s toy)',
  'to whiz, whir',
  'Samoan (of, from, or pertaining to Samoa)',
  'again, afresh, anew',
  'chewy',
  'garage (building or section of building to store a car, tools etc.)',
  'to chatter, prattle',
  'ventral',
  'accent (distinctive manner of pronouncing a language)',
  'public',
  'result, outcome, effect',
  'attached to prepositions to indicate the plural 2nd person; you',
  'great-grandchild, great-great-grandchild',
  'female equivalent of ܫܵܩܝܵܐ (šāqyā, “cupbearer”)',
  'a white person',
  'blue',
  'pink',
  'gray/grey',
  'to misbehave, mess around',
  'to blush',
  'far, distant, remote (in space or time)',
  'clutch',
  'Baháʼí (pertaining to Baháʼís or beliefs held in the Baháʼí Faith)',
  'thin, delicate, slim',
  'person, individual',
  'light, bright, semidark in color',
  'dark in colour',
  'shirt, T-shirt',
  'dry, arid (not wet; lacking moisture or humidity)',
  'much, many, multiple, numerous, several',
  'at least',
  'middle, central',
  'koala',
  'to focus, concentrate',
  'fresh',
  'to become dirty, foul',
  'guy, boy, young man, young unmarried man',
  'behind, backward',
  'to picnic, to have a picnic',
  'Alternative form of ܒܪ̈ܲܚܡܹܐ (braḥmē)',
  'heavy',
  'pleasant',
  'inwardly',
  'forgetting, neglecting',
  'to share, participate, take part',
  'only, just',
  'on the contrary',
  'siege, military, blockade',
  'deliberation',
  'ready, prepared',
  'case, matter, business, affair',
  'kitchen',
  'restaurant (an eating establishment in which diners are served food)',
  'humid, moist, damp',
  'second-person plural imperative of ܐܵܙܹܠ (āzēl)',
  'deep',
  'nickname (familiar, invented given name)',
  'structure, building',
  'university student',
  'rooted',
  'constant, continuous, continual',
  'oblique, slanting',
  'open, unlocked',
  'human, of or relating to human beings or humankind',
  'shallow, superficial',
  'asleep, sleeping',
  'cooked',
  'smart, intelligent',
  'wise, smart',
  'serious',
  'righteous',
  'past participle of ܚܵܠܹܡ (ḥālēm, “to dream”)',
  'sour, unripe, acidic',
  'salty, saline',
  'second-person plural imperative of ܐܵܬܹܐ (ātē)',
  'parliament (the legislative body in a parliamentary political system)',
  'careful',
  'more, most',
  'burned, burnt (damaged or injured by fire or heat)',
  'less, fewer',
  'gathered, collected',
  'hungry (affected by hunger; desirous of food)',
  'written',
  'immediate, instant',
  'bared (of teeth)',
  'sad, unhappy, sorrowful (experiencing the emotion of unhappiness)',
  'semaphore, signal (equipment used for visual signalling)',
  'holiday',
  'to ponder, contemplate, speculate',
  'easy, plain',
  'Ethiopian (of, from, or pertaining to Ethiopia, the Ethiopian culture or people)',
  'high, raised',
  'bracelet, armlet',
  'plural of ܡܕܝܼܢ݇ܬܵܐ (mdītā, “city”)',
  'nearby, neighboring, adjacent',
  'present',
  'normal, regular, ordinary, usual',
  'sharp, pointed',
  'Nazarene (of or pertaining to Nazareth or its people)',
  'migrant, immigrant',
  'to scratch, claw',
  'married',
  'horizon (line that appears to separate the Earth from the sky)',
  'distress, stress, anxiety, discomfort',
  'original, authentic',
  'honest, sincere, truthful, telling the truth',
  'copy',
  'prototype (an original object or form which is a basis for other objects)',
  'remaining, left,',
  'to design',
  'disk',
  'smith, metalworker',
  'copyist, scribe',
  'tight, fastened',
  'past participle of ܣܵܒ݂ܹܪ (sāḇēr); anticipated, expected',
  'sensitive, endowed with senses',
  'empathetic, sympathetic, pitiful',
  'in the evening',
  'to masturbate',
  'strange, weird, odd, peculiar',
  'past participle of ܙܵܓ݂ܹܪ (zāḡēr)',
  'arm',
  'to roll',
  'to wrap, enfold, enclose',
  'confident, trusting',
  'shutter, blind (protective panels over windows)',
  'The next, coming, upcoming, following, subsequent',
  'testament',
  'wrestling, wrestling match',
  'invader, occupier',
  'different, unlike, dissimilar, not the same as',
  'slow (not quick in motion)',
  'blanket',
  'different',
  'quilt, comforter, duvet',
  'necessary, indispensable, unavoidable, essential, inevitable, inescapable, requisite',
  'karat, carat (measure for purity of gold, precious stones and pearls)',
  'thirty (30)',
  'twenty (20)',
  'ninety (90)',
  'sixty (60)',
  'eighty (80)',
  'fifty (50)',
  'cheese',
  'A dish made from buttermilk and grains',
  'to thunder',
  'free of charge, gratis',
  'straight, direct',
  'opening',
  'around',
  'to search, go through another’s belongings in a violating manner',
  'Alternative form of ܦܲܠܝܼܛܵܐ (palīṭā, “planet”)',
  'available, readily obtainable',
  'cute, sweet, charming',
  'aid, assistance, rescue',
  'shoulder',
  'rubber',
  'famous, well-known, renowned',
  'experienced, having experience and skill in a subject',
  'smelly, foul-smelling, stinky',
  'solicitous, desiring, eager',
  'then, afterward, subsequently; used to indicate order of time, being later occurring, or even in lesser degree of impressiveness',
  'clod, lump',
  'swell, swelling',
  'disabled, handicapped',
  'episode',
  'to subscribe, subscribe oneself',
  'to damage, injure, harm, ruin',
  'catastrophe, disaster, tragedy',
  'to torment, afflict, torture',
  'to disgust, revolt, nauseate',
  'soup, broth, stew',
  'to realize, become aware of',
  'existing, being',
  'bright, shining',
  'cycle, circuit, period',
  'safe, secure, sound',
  'artificial, synthetic, man-made',
  'Introduces a subjunctive verb clause often corresponding to the English infinitive',
  'violent, oppressive',
  'change, small change, loose change',
  'instance of sitting',
  'false, fake, untrue',
  'incorrect, wrong, erroneous, mistaken',
  'wish, desire, hope',
  'individual small slope or incline of a hill',
  'measure, measurement',
  'to drip copiously, trickle',
  'splendid, shining, luminous, glowing',
  'warm',
  'angry, furious, enraged, vexed',
  'pole',
  'protector, patron, overseer, officer, official',
  'aerial, air-',
  'terminal',
  'response, reaction, reply',
  'equipment, device, appliances, outfit, gear, rig',
  'to trip, stumble',
  'to meow',
  'tenth (⅒)',
  'eighth (⅛)',
  'thousandth (1000th)',
  'tens (of something); indicates an indefinite number approximately between ten and a hundred',
  'seventh (⅐)',
  'ninth (⅑)',
  'arrival',
  'straight',
  'to tap, pat, hit gently',
  'to push',
  'to slap, slam, smack',
  'pastry, baked good (baked food item made from flour and fat pastes such as pie crust)',
  'separated, distinct, clear, different',
  'interchange, highway junction where streams of traffic do not intersect',
  'wing, fin',
  'plural of ܚܘܼܘܹܐ (ḥūwē, “snake”)',
  'helium',
  'emergency',
  'bay, gulf (a body of water, extending from the sea, that is mostly surrounded by land)',
  'departure, leaving',
  'asleep, sleeping',
  'shopping trolley; cart, wagon, carriage',
  'to screech, scream, shriek',
  'thin, flat, slim, fine, subtle',
  'bucket, pail (especially metallic or plastic, with one handle)',
  'dimension',
  'precise, accurate, exact',
  'sticky, adhesive',
  'to confuse, to bewilder, to perplex',
  'to trample, tread on/over, run over',
  'maybe, perhaps',
  'to wet one’s feet',
  'human being, person',
  'to rinse',
  'cage, birdcage, pen, coop',
  'mill',
  'business, businesslike',
  'note; melody, tone, sound',
  'parachute (device designed to control the fall of an object or person)',
  'ring',
  'solvent, dissolving, dilutant',
  'to legalize, make lawful, permit',
  'to steer',
  'to make an enemy',
  'stem',
  'skilled, talented, diligent',
  'to narrate history, give the history of',
  'cheek',
  'to develop spots, bubbles, blisters, pustules, etc.',
  'interest, something one finds interesting',
  'to prefer, favor',
  'to fog, fog up',
  'drink, beverage',
  'husband’s brother',
  'wife’s sister’s husband',
  'which',
  'to cause, reason',
  'fingernail, toenail',
  'to complete, finish, fill out',
  'engaged, betrothed',
  'organization',
  'complete, full, total, whole',
  'hatred',
  'outgoing',
  'incoming',
  'plateau',
  'safe, riskless, harmless',
  'dangerous, hazardous, perilous',
  'noisy, clamorous, vociferous',
  'avenue, boulevard, wide city street',
  'existent, present',
  'idle, inactive; invalid, null, void',
  'active, operative, in action, working, in force',
  'to encounter, meet by chance, bump into, run into',
  'noble, aristocratic, refined, honourable',
  'gate, gateway',
  'sofa, couch, settee',
  'headscarf',
  'power, strength; fortitude, endurance',
  'rolling pin',
  'wet, moist, damp',
  'composer, compiler, author',
  'to hurry, rush, hasten',
  'deprived, disadvantaged',
  'studio, atelier',
  'plural of ܓܘܼܬܵܐ (guttā, “ball”)',
  'cool, breezy',
  'round',
  'a video (short film clip, with or without audio)',
  'that one',
  'a film, movie',
  'intermission, pause, break',
  'crowded',
  'debit',
  'eloquent, loquacious, voluble; clear and distinct in speech',
  'skilled, proficient, adept',
  'with force, forcefully',
  'weak, feeble, frail',
  'fit, in shape, physically active',
  'edamame',
  'ventilation hatch of attic, hatch by which grain is poured into the attic granary',
  'crutch',
  'in the morning',
  'to whip',
  'candy, sweet',
  'solid',
  'dark',
  'camomile',
  'to stir',
  'hostage',
  'sour, unripe grape',
  'stock market, stock exchange, bourse',
  'acceptance, receiving, admission',
  'edge of clothing',
  'busy, occupied',
  'pus, purulence',
  'scorched rice in the bottom of a pot',
  'emotional',
  'gasoline, petrol, motor fuel',
  'fricative',
  'rubbing, chafing, scratching',
  'mouthful, bite (of food)',
  'gleaning, gathering',
  'to scribble, scrawl, scratch, doodle',
  'sip, gulp',
  'nobody, no one, not one',
  'trip, tour, excursion; travel, journey',
  'useful, practical, handy',
  'not even, not at all',
  'addicted',
  'necessary, obligatory, compulsory',
  'right, appropriate, fitting, suitable',
  'clear, distinct, plain,',
  'tobacco',
  'boiling',
  'coming',
  'addict',
  'to be/become excessive, extravagant, immoderate',
  'plan, set of intended actions',
  'keeper, one who keeps',
  'jewel, precious stone, gem, gemstone (especially an emerald)',
  'ornament, a piece of jewellery',
  'hut, shack, cottage, lodge, hutch, cabin',
  'medal',
  'belt, girdle, waistband, sash, strap',
  'done, made, created',
  'physics',
  'expert, experienced, proficient',
  'dress (item of women’s clothing)',
  'basin, washbasin, laver; sink for washing dishes',
  'game',
  'biased, prejudiced',
  'to rattle',
  'to enslave, subjugate, enthrall',
  'stall, kiosk, booth (small open-fronted shop) (in a market, food court, etc.)',
  'silk',
  'dye, pigment',
  'toy',
  'shy, bashful, easily embarrassed',
  'to flake, flake off',
  'to examine, search, look into, scrutinize; to go through, look through, read through',
  'cover, anything that covers: covering, lid, blanket, etc.',
  'tourist',
  'heir, inheritor',
  'traveler',
  'childish, infantile, immature, juvenile',
  'warning',
  'pickaxe',
  'plural of ܐܝܼܩܲܪܬܵܐ (īqartā, “family”)',
  'scan',
  'clear out, evacuation',
  'ruler (measuring or drawing device)',
  'to crush, to bruise',
  'navel, belly button, umbilicus',
  'pistachio',
  'blade',
  'object, item, article',
  'to gush, pour, flow down with plashing',
  'skinny, tight-fitting, skintight (of clothing)',
  'tight',
  'turned off, switched off, off, extinguished, unlit',
  'goatskin or sheepskin bag used to store meat or grain',
]
export const PARTS_OF_SPEECH = [
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'pron',
  'pron',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adv',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'verb',
  'adj',
  'num',
  'num',
  'noun',
  'noun',
  'adv',
  'num',
  'num',
  'num',
  'num',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'verb',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'verb',
  'noun',
  'adj',
  'adj',
  'noun',
  'adj',
  'noun',
  'verb',
  'pron',
  'noun',
  'adj',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'adj',
  'noun',
  'adj',
  'num',
  'noun',
  'noun',
  'verb',
  'verb',
  'noun',
  'noun',
  'noun',
  'num',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'adj',
  'adj',
  'verb',
  'noun',
  'noun',
  'noun',
  'verb',
  'verb',
  'verb',
  'noun',
  'adj',
  'verb',
  'pron',
  'noun',
  'adj',
  'adj',
  'adv',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'intj',
  'verb',
  'noun',
  'verb',
  'verb',
  'noun',
  'noun',
  'intj',
  'verb',
  'verb',
  'verb',
  'noun',
  'adj',
  'noun',
  'noun',
  'adj',
  'noun',
  'adj',
  'noun',
  'num',
  'adj',
  'noun',
  'noun',
  'adj',
  'verb',
  'verb',
  'adj',
  'verb',
  'adj',
  'intj',
  'noun',
  'adv',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'noun',
  'adv',
  'noun',
  'noun',
  'verb',
  'adj',
  'adj',
  'adv',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'adv',
  'adj',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'adj',
  'noun',
  'noun',
  'verb',
  'verb',
  'adj',
  'noun',
  'noun',
  'verb',
  'noun',
  'verb',
  'prep',
  'adj',
  'verb',
  'adj',
  'verb',
  'noun',
  'verb',
  'adj',
  'adv',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'adj',
  'verb',
  'noun',
  'verb',
  'phrase',
  'noun',
  'noun',
  'noun',
  'conj',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'verb',
  'verb',
  'noun',
  'verb',
  'adj',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'adj',
  'pron',
  'num',
  'adj',
  'adj',
  'verb',
  'noun',
  'noun',
  'adj',
  'verb',
  'adj',
  'verb',
  'verb',
  'adj',
  'adj',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'num',
  'noun',
  'verb',
  'noun',
  'noun',
  'num',
  'num',
  'noun',
  'num',
  'noun',
  'noun',
  'verb',
  'adj',
  'adv',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'adj',
  'verb',
  'adv',
  'verb',
  'noun',
  'adv',
  'pron',
  'verb',
  'adj',
  'adj',
  'adv',
  'adj',
  'verb',
  'adj',
  'noun',
  'verb',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adv',
  'adj',
  'verb',
  'noun',
  'verb',
  'adj',
  'adj',
  'verb',
  'prep',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'verb',
  'adv',
  'adv',
  'adj',
  'noun',
  'verb',
  'adj',
  'adv',
  'adj',
  'noun',
  'verb',
  'adj',
  'noun',
  'adj',
  'noun',
  'particle',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'verb',
  'verb',
  'adj',
  'noun',
  'adj',
  'adj',
  'noun',
  'adj',
  'adj',
  'noun',
  'adj',
  'adj',
  'prep',
  'adj',
  'noun',
  'verb',
  'adj',
  'verb',
  'noun',
  'adv',
  'verb',
  'intj',
  'adj',
  'adj',
  'adv',
  'noun',
  'verb',
  'adv',
  'adv',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'verb',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'noun',
  'noun',
  'verb',
  'adj',
  'adj',
  'adj',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'adj',
  'noun',
  'verb',
  'adj',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'adj',
  'verb',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'adv',
  'verb',
  'adj',
  'adj',
  'noun',
  'verb',
  'verb',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'adj',
  'noun',
  'adj',
  'noun',
  'num',
  'num',
  'num',
  'num',
  'num',
  'num',
  'noun',
  'noun',
  'verb',
  'adj',
  'adj',
  'noun',
  'prep',
  'verb',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'adv',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'verb',
  'noun',
  'verb',
  'verb',
  'noun',
  'verb',
  'adj',
  'adj',
  'noun',
  'adj',
  'adj',
  'conj',
  'adj',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'verb',
  'adj',
  'adj',
  'adj',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'verb',
  'verb',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'verb',
  'verb',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'adj',
  'noun',
  'noun',
  'adj',
  'adj',
  'verb',
  'verb',
  'adv',
  'verb',
  'noun',
  'verb',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'verb',
  'verb',
  'noun',
  'adj',
  'verb',
  'noun',
  'verb',
  'noun',
  'verb',
  'verb',
  'noun',
  'noun',
  'noun',
  'pron',
  'verb',
  'noun',
  'verb',
  'adj',
  'noun',
  'adj',
  'noun',
  'adj',
  'adj',
  'noun',
  'adj',
  'adj',
  'adj',
  'noun',
  'adj',
  'adj',
  'adj',
  'verb',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'adj',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'pron',
  'noun',
  'noun',
  'adj',
  'noun',
  'adj',
  'adj',
  'adv',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'adv',
  'verb',
  'noun',
  'adj',
  'adj',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'pron',
  'noun',
  'adj',
  'adv',
  'adj',
  'adj',
  'adj',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'adj',
  'adj',
  'adj',
  'noun',
]
